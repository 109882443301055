import { Wrapper, Options} from './styles';
import { useNavigate } from "react-router-dom";
import SettingsIcon from '@mui/icons-material/Settings';
import { SiGoogleanalytics } from "react-icons/si";
import { Tooltip } from "@mui/material"
import ModalComponent from '../Modal/modal'
import React, { useState } from 'react';
export const AgentAssistHeader = ({ isAdmin = false , isTicketIdValid = false, subDomain,ticketId,email,ticketingSystem,orgId,prismToken}) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [modalUrl, setModalUrl] = useState('');
    const handleOpenModal = (id, title) => {
        const url = encodeURI(
          `https://www.prism.readyly.com/?orgid=${orgId}&bearerToken=${prismToken}&isAccessFromAA=true`
        );
        setModalUrl(url);
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
        setModalUrl('');
      };
      const handleProceed = () => {
        window.open(modalUrl, '_blank');
        handleClose();
      };
    return (
        <>
        <div class="readyly-banner readyly-app-name" style={{justifyContent:"space-between"}}>
        <div id="logo-container">
            <span class ="readyly-app-name">Readyly GPT Agent Assist</span>
            <div  class ="readyly-logo"></div>
        </div>
        {isTicketIdValid && <Wrapper style={{marginRight:"10rem"}}>
            <Options onClick={()=>navigate(`/app/?subDomain=${subDomain}&ticketId=${ticketId}&email=${email}&ticketingSystem=${ticketingSystem}`)}>GPT</Options>
            <Options onClick={()=>navigate(`/app/research?subDomain=${subDomain}&ticketId=${ticketId}&email=${email}&ticketingSystem=${ticketingSystem}`)}> Research</Options>
            <Options onClick={()=>navigate(`/app/summary?subDomain=${subDomain}&ticketId=${ticketId}&email=${email}&ticketingSystem=${ticketingSystem}`)}>Summary</Options>
            {isAdmin && <Options onClick={()=>handleOpenModal()}><Tooltip title="Analytics"><SiGoogleanalytics /> </Tooltip></Options>}
            {/* {isAdmin && <Options> <SettingsIcon/> </Options>} */}
        </Wrapper>}

    </div>

    <ModalComponent
        open={open}
        handleClose={handleClose}
        handleProceed={handleProceed}
        title="Open Analytics"
        description="Do you want to proceed to the Analytics in a new tab?"
      />
    </>
    )
  }