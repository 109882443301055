import { SimilarTickets } from "./SimilarTickets"
import {RelevantArticles} from './RelevantArticles'
import {useDispatch, useSelector} from 'react-redux';
import { useEffect } from "react"
import {Workflows} from './Workflows'
import {getAggregataForSearchQuery} from '../../redux/actions/searchActions'
import CircularIndeterminate from "../circularspinner/circularspinner";
export const Search = ({searchQuery,subDomain,ticketingSystem,prismToken,ticketId,orgId,agentDetails,userEmail,ticketDetails,validSearch = false})=>{
    
    const dispatch = useDispatch();

    const similarTickets = useSelector((state)=>state.search.searchSimilarTickets);
    const relevantArticles = useSelector((state)=>state.search.searchRelevantArticles);

    const prevSearchQuery = useSelector((state)=>state.search.searchQuery);

    const isRelevantArticlesLoading = useSelector ((state)=>state.search.searchArticlesloading)
    const isloading = useSelector((state)=>state.ticket.researchTabloading);
    const buttonLabels = useSelector((state) => state.article.searchArticlesbuttonlables);
    const loading = useSelector((state) => state.article.searchArticlesloading);
    const articleResponses = useSelector(state => state.article.searchArticleResponses);

    const workflowsLoading = useSelector((state)=>state.search.searchWorkflowsLoading)
    const workflows = useSelector((state)=>state.search.searchWorkflows)
    useEffect(()=>{
        if(validSearch && prevSearchQuery !== searchQuery){
            dispatch(getAggregataForSearchQuery(searchQuery,ticketDetails,agentDetails,userEmail,prismToken,orgId))
        }
    },[validSearch])
    

    const searchResults =  similarTickets.length || relevantArticles.length;
    
    return <>
    {!searchResults ?
        <div>
                        <span>
                            Click on <a href="#TicketResearch" style={{fontWeight:"700",textDecoration:"none",color:"#152938"}} >Ticket Research</a>&nbsp;
                            or use the <a href="#search-input"style={{fontWeight:"700",textDecoration:"none",color:"#152938"}} >Search Bar</a>&nbsp;
                            to get started
                        </span>
    </div>
    :
    <div >
            { isloading ? <CircularIndeterminate height={"10vh"}/> : 
            <div >
                <Workflows workflows = {workflows} isloading = {workflowsLoading} userEmail ={userEmail} ticketDetails = {ticketDetails} orgId = {orgId} prismToken = {prismToken} renderType = {"ticket"} subDomain={subDomain} ticketingSystem = {ticketingSystem} agentDetails = {agentDetails} queryText={ticketDetails?.latest_question_from_customer ?? ticketDetails?.subject}/>
                <RelevantArticles relevantArticles={relevantArticles} articleResponses={articleResponses} isLoading={isRelevantArticlesLoading} buttonLabels={buttonLabels} loading={loading} prismToken = {prismToken} ticketDetails={ticketDetails} userEmail={userEmail} renderType={"search"} orgId={orgId} queryText={searchQuery} ticketingSystem={ticketingSystem} subDomain={subDomain}/>
                <SimilarTickets similarTickets={similarTickets} userEmail= {userEmail} ticketDetails = {ticketDetails} orgId={orgId} prismToken={prismToken} renderType={"search"} agentDetails = {agentDetails} ticketingSystem={ticketingSystem} subDomain={subDomain} queryText={searchQuery}/>
            </div>}
    </div>
}

    </>
}