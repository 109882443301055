// Packages:
import { styled } from '@mui/material/styles'

// Exports:

export const Wrapper = styled('div')(({ theme }) => ({

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    marginTop: "2.438rem",
 

[theme.breakpoints.down('lg')]: {
    width: "98%"
  },
}))

export const Subtitle = styled('div')({

    marginBottom: "2.188rem",
    fontSize: "16px",
    color: "#333333"
})

export const Form = styled('div')({

    marginBottom: "1rem",
    padding: "2.25rem 1.5rem 4rem",
    fontSize: "16px",
    boxShadow: "rgba(136, 165, 191, 0.48) 2px 3px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px",
    background: "#ffffff",
    borderRadius: "5px",

})
  
export const InputField = styled('div')({
  marginBottom: "2rem",
})

export const Tag = styled('div')(({ theme }) => ({

  marginBottom: "0.25rem",
  fontSize: "0.875rem",
  color: "#828282",


  [theme.breakpoints.down('xl')]: {
    fontSize: "0.775rem",
  },

  [theme.breakpoints.down('xl')]: {
    fontSize: "0.675rem",
  },
}))

export const InputArea = styled('div')(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent:"space-between",
  width: "26.875rem",
  padding: "0.75rem 1rem",
  fontSize: "1.25rem",
  border: "1px solid #e0e0e0",
  borderRadius: "4px",
  backgroundColor: "#e8f0fe",

  [theme.breakpoints.down('xl')]: {
    fontSize: "1.15rem",
    width: "25rem",
  },

  [theme.breakpoints.down('lg')]: {
    fontSize: "1rem",
    width: "19rem",
  },
}))

export const Input = styled('input')(({ theme }) => ({
  width: "calc(100% - 1rem)",
  fontFamily: 'Inter',
  backgroundColor: "transparent",
  border: "none",
  outline: "none",

  // [theme.breakpoints.down('xl')]: {
  //   fontSize: "0.75rem",
  // },
}))

export const Checkbox = styled('span')({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "1rem",
  height: "1rem",
  border: "1px solid #e0e0e0",
  borderRadius:" 2.5px",
  cursor: 'pointer',
})
