import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
function LandingPage(){
     const navigate = useNavigate();

    const handleClick = () => {
        navigate("/home");
    }
    return (
            <div className="App">
              <header className="App-header">
                <h2>Readyly Gorgias Agent Assist App</h2>
                <Button variant="contained" className="mt-3" onClick={handleClick}>Go to App</Button>
              </header>
            </div>
          );
}
export default LandingPage