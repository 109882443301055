// Packages:
import React, { useState, useCallback,memo, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../utils/auth';
import Button from '@mui/material/Button';
import LoginIcon from '@mui/icons-material/Login';
import { ReadylyHeader }  from '../../components/ReadylyHeader/ReadylyHeader'
import {useDispatch} from 'react-redux'


// Imports:
import { HiOutlineMail } from 'react-icons/hi';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { BiCheck } from 'react-icons/bi';
import debounce from 'lodash/debounce';

// Constants:
import ROUTES from '../../constants/routes';

// Styles:
import {
  Wrapper,
  Subtitle,
  Form,
  InputField,
  Tag,
  InputArea,
  Input,
  Checkbox
} from './styles';
import { useSelector } from 'react-redux';
import CircularIndeterminate from '../../components/circularspinner/circularspinner';
import { getOrgData } from '../../api';

// Functions:
const Login =memo(() => {
  // Constants:
  const auth = useAuth();
  const location=useLocation()
  const redirectPath=location.state?.path??ROUTES.INDEX
  const dispatch = useDispatch();

  // State:
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [rememberPassword, setRememberPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const orgDetails = useSelector((state) => state.auth.orgDetails);
  const orgid = orgDetails?._id;
  const isOrgDataloading = useSelector((state)=>state.auth.isOrgDataloading)

  const debouncedSetEmail = debounce((value) => setEmail(value), 500);
  const debouncedSetPassword = debounce((value) => setPassword(value), 500);


  const queryParams = new URLSearchParams(location.search);
  
  const subDomain =  queryParams.get('subDomain');

  const changeLoading = useCallback(() => {
    setIsLoading((prev) => !prev);
  }, []);

  // Functions:
  const handleLogin = useCallback(
    async (event) => {
      event.preventDefault();
      setIsLoading(true);
      auth.login(email.toLowerCase(), password, changeLoading,redirectPath);
    },
    [auth, email, password]
  );

  useEffect(()=>{
    dispatch(getOrgData(subDomain))
  },[subDomain])
  // Return:
  return (
    <>
      <ReadylyHeader/>
      {subDomain  ? isOrgDataloading ? <CircularIndeterminate/> :
      <div style={{display:"flex",height:"100vh"}}>
      <Wrapper >
        <Subtitle>Powering speed and accuracy.</Subtitle>
        <Form>
          <InputField>
            <Tag>Email address</Tag>
            <InputArea>
              <Input
                type="text"
                placeholder="Email address"
                onChange={(e) => debouncedSetEmail(e.target.value)}
              />
              <HiOutlineMail style={{ color: '#333333'}}/>
            </InputArea>
          </InputField>
          <InputField>
            <Tag>Password</Tag>
            <InputArea>
              <Input
                type={isPasswordVisible ? 'text' : 'password'}
                placeholder="Password"
                onChange={(e) => debouncedSetPassword(e.target.value)}
              />
              {isPasswordVisible ? (
                <AiOutlineEye
                  style={{ cursor: 'pointer', color: '#333333' }}
                  onClick={() => setIsPasswordVisible(false)}
                />
              ) : (
                <AiOutlineEyeInvisible
                  style={{ cursor: 'pointer', color: '#333333' }}
                  onClick={() => setIsPasswordVisible(true)}
                />
              )}
            </InputArea>
          </InputField>
          <InputField>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <div style={{ display: 'flex' }}>
                <Checkbox onClick={() => setRememberPassword(!rememberPassword)}>
                  <BiCheck
                    style={{
                      filter: `opacity(${rememberPassword ? 1 : 0})`,
                      transition: 'all 0.25s ease'
                    }}
                  />
                </Checkbox>
                <Tag style={{ marginLeft: '0.5rem' }}>Remember password</Tag>
              </div>
              <Button
                variant="contained"
                size="medium"
                disabled={isLoading}
                startIcon={<LoginIcon />}
                onClick={(event) => !isLoading && handleLogin(event)}
                isLoading={isLoading}
                sx={{ textTransform: 'none' }}
              >
                Login
              </Button>
            </div>
          </InputField>
        </Form>
        <Link style={{ fontSize: '0.9rem' }} to={ROUTES.AGENT.FORGOT_PASSWORD}>
          Forgot Password?
        </Link>
      </Wrapper>
      </div> : <Wrapper style={{margin:"auto",fontSize:"2rem",position:"relative",top:"50vh"}}> We dont have your domain details</Wrapper>}
    </>
    
  );
})


// Exports:
export default  Login;
