import React, { forwardRef, memo } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const Editor = forwardRef(({ content, onChange }, ref) => {
    return (
        <ReactQuill 
            ref={ref} 
            value={content} 
            onChange={onChange} 
            style={SandBoxStyle}
        />
    );
});

const ReactQuillEditor = memo(Editor, (prevProps, nextProps) => {
    return prevProps.content === nextProps.content;
});

export default ReactQuillEditor

const SandBoxStyle= {
    boxShadow: "none",
    fontSize: "1rem",
    fontWeight: "400",
    outline: "0px solid transparent",
    lineHeight: "1.5",
    width:"100%",
    height:"50%",
    border:"none",
}