import { AgentAssistHeader } from "../../components/AgentAssisHeader/AgentAssisHeader.js"
import { ResearchComponent } from "../../components/ResearchComponent/ResearchComponent.js"
import {  useLocation } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import React, { useEffect} from 'react';
import {getTicketDetails,getAgentDetails,getOrgData} from '../../api.js'
import CircularIndeterminate from "../../components/circularspinner/circularspinner.js";
import { Form } from "../login/style.js";

export const Research= ()=>{


    const agentDetails = useSelector((state)=>state.auth.agentDetails);
    const orgDetails =  useSelector((state)=>state.auth.orgDetails);
    const ticketDataloading = useSelector((state)=>state.ticket.ticketDetailsLoading);
    const orgDetailsLoading = useSelector((state)=>state.auth.orgDetailsLoading);
    const agentDetailsLoading = useSelector((state)=>state.auth.agentDetailsLoading);
    const ticketId = useSelector((state)=>state.ticket.ticketId);
    const subDomain = useSelector((state)=>state.auth.domain);
    const email =  useSelector((state)=>state.auth.email);
    const ticketingSystem = orgDetails?.ticketing?.platform;
    const prismToken = useSelector((state)=>state.auth.prismToken);
    const isAuthenticatedUser = useSelector((state)=>state.auth.isAuthenticatedUser);

    const dispatch = useDispatch();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);


    useEffect(()=>{
        const paramSubdomain = queryParams.get('subDomain');
        if (!subDomain && paramSubdomain ){
            dispatch(getOrgData(paramSubdomain));
        }
    },[])

    useEffect(()=>{
        const paramUserEmail = email || queryParams.get('email');
        const paramTicketId = ticketId || queryParams.get('ticketId');
        const paramticketingSystem = ticketingSystem || queryParams.get("ticketingSystem") ;
        if (!email && paramUserEmail && prismToken && orgDetails?._id){
            dispatch(getAgentDetails(paramUserEmail,paramticketingSystem,orgDetails?._id,prismToken));
        }
        if (!ticketId && paramTicketId && prismToken && orgDetails?._id){
            dispatch(getTicketDetails(paramTicketId,subDomain,paramticketingSystem,orgDetails?._id,prismToken));
        }

    },[orgDetails])


    const isValid = Object.keys(orgDetails).length > 0 && agentDetails?.verified;
    const loading = ticketDataloading || orgDetailsLoading || agentDetailsLoading;
    const isTicketIdValid = ticketId;
    const isAdmin = Object.keys(agentDetails).length > 0 && agentDetails?.role.toLowerCase() === "admin";

    return <>
    <AgentAssistHeader isAdmin={isAdmin} isTicketIdValid={isTicketIdValid} subDomain={subDomain} ticketId={ticketId} email={email} ticketingSystem={ticketingSystem} orgId={orgDetails?._id} prismToken={prismToken}/>

    {loading ? <CircularIndeterminate/> : isValid  ? 
        <ResearchComponent subDomain={subDomain} ticketingSystem={ticketingSystem} prismToken={prismToken} ticketId={ticketId} orgId={orgDetails?._id} agentDetails= {agentDetails} userEmail = {email}/> : 

        <div >
            <Form style={{display:"flex",justifyContent:"center",alignItems:"center",height:"50vh"}}>

            {Object.keys(orgDetails).length > 0 ?  <h3> Invalid Email Id</h3> : <h3> Invalid Sub Domain</h3>}
            </Form>
        </div>
}
    </>
}