import { styled } from '@mui/material/styles';
export const Wrapper = styled('div')({
    width:"100%",
    overflowY: "scroll",
    lineHeight: "1.5",
    height:'300px',
    
    // overflow:"hidden",
})

export const Key = styled('p')({
    fontWeight:"600",
    textTransform:"capitalize",
    marginBottom:"5rem",
    fontSize: "90%"

})
export const Value = styled('span')({
    fontWeight: 400,
    fontSize: "90%"
})

export const Ball = styled('span')({
    height:"1rem",
    width:"1rem",
    marginTop:"0.3rem",
    backgroundColor: "#152938",
    borderRadius:"50%",
    display:"inline-block"
})
export const PastTicket = styled('div')(
    {
        margin:"1rem",
        display:"flex",
        flexDirection:"row",
        justifyContent:"space-around",
        width:"100%",
        height:"6rem"}
)
export const Divider = styled('div')(
    {display:"flex",
    height:"6rem",
    flexDirection:"column",
    alignItems:"center",
    justifyContent:"space-between"}
)

export const DividerLine = styled('div')({
    width:"0.1rem",
    height:"4rem",
    border:"1px solid #B3B3B3"
})

export const PastTicketDetails = styled('div')({
    display:"flex",
    flexDirection:"column",
    overflow:"hidden",
    height:"7rem",
    width:"12rem"
})

export const PastTicketSubject = styled('p')({
    width:"100%",
    display: "-webkit-box",
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    height:"3rem",
    margin:"0"
})