import * as React from 'react';
import { useDispatch } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { styled } from '@mui/system';
import { toggleSnackbar } from '../../redux/actions/authActions';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Container = styled(`div`)({
    display : "flex",
    justifyContent : "center",

})


const ErrorSnackbar = React.memo(function MyMemo(props) {
    const dispatch = useDispatch()

    const handleClose = () => {
        dispatch(toggleSnackbar({
            status: false,
            message : props.message,
            severity:props.severity
        }))
    }
    return (
        <>
            <Container>
                <Snackbar open={props.isOpen} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
                    <Alert onClose={handleClose} severity={props.severity} sx={{ width: '100%' }}>
                        {props.message}
                    </Alert>
                </Snackbar>
            </Container>
        </>
    );
}
)



export default ErrorSnackbar