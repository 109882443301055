import {Fragment, useEffect } from "react"
import CircularIndeterminate from "../circularspinner/circularspinner"
import {Wrapper,Key,Value} from './styles'
import { useSelector } from "react-redux";
import { getCustomerSummary } from "../../api";
import { useDispatch } from "react-redux";

export const CustomerSummary = ({subDomain,ticketingSystem,ticketDetails,orgId,prismToken,userEmail}) => {
    const customerSummaryContent = useSelector((state)=> state.summary.customerSummaryContent);
    const customerSummaryLoading = useSelector((state)=> state.summary.customerSummaryLoading);
    const dispatch = useDispatch();
    useEffect(()=>{

        dispatch(getCustomerSummary(subDomain,ticketingSystem,ticketDetails,userEmail,orgId,prismToken))
    },[])
    
    return (
        <>
            {customerSummaryLoading ? <CircularIndeterminate height={"20vh"} /> : 
                <Wrapper style={{height:"inherit"}}>
                    {Object.entries(customerSummaryContent).map(([key, value]) => (
                        <Key key={key}>
                            {key} : <Value>{value.split('\n').map((line, index) => <Fragment key={index}>{line}<br/></Fragment>)}</Value>
                        </Key>
                    ))}
                </Wrapper>
            }
        </>
    );
};

