import './style.css'
export const ReadylyHeader = ()=>{
    return (
        <div class="readyly-banner" style={{justifyContent:"flex-start"}}>
            <div id="logo-container">
                <span class ="readyly-app-name">Readyly Agent Assist</span>
                <div  class ="readyly-logo">
                </div>
                
            </div>

        </div>
    )
}