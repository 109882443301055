import { Form,Wrapper,Tag,Input,InputArea,InputField,ErrorMessage } from "../../pages/login/style"
import { Tooltip } from "@mui/material"
import Button from '@mui/material/Button';
import {getTicketDetails} from '../../api'
import { useDispatch } from 'react-redux';
import React, {  useState} from 'react';



export const TicketComponent = (props)=>{

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [currentTicketId,setTicketId] = useState(props?.ticketId);
    const dispatch = useDispatch();

    function submitTicket(){
        setIsSubmitted(true);
        if (currentTicketId.trim() === '') {
            return;
          }
        dispatch(getTicketDetails(currentTicketId, props?.subDomain,props?.ticketingSystem,props?.orgId,props?.prismToken));
    }


    return (
        < Form style={{padding:"1rem",height:'20%',width:"80%",display:"flex",justifyContent:"center",boxShadow:"15px 15px 30px rgb(209, 217, 230),-15px -15px 30px rgb(209, 217, 230)"}}>
            <Wrapper style={{width:"100%",justifyContent:"space-around",top:"0",height:"auto",flexDirection:"row",boxShadow:'none'}}>
                <InputField>
                    
                    <Tag style={{marginBottom:"1rem"}}>Please enter ticketId : <span style={{color:"red"}}>*</span></Tag>
                        <InputArea>
                            <Input style={{width:"inherit"}}
                            type="text"
                            placeholder="Enter ticket id :"
                            value={currentTicketId}
                            onChange={(e) => setTicketId(e.target.value)}
                            />
                        </InputArea>
                        {isSubmitted && currentTicketId.trim() === '' && (
                        <ErrorMessage>Please enter ticket Id</ErrorMessage>
                        )}
                </InputField>
                <Tooltip title="Generate App Insights for this ticket">
                            <Button style={{marginTop:'1.75rem',height:'2.5rem'}} variant="contained" onClick={submitTicket}>GO</Button>
                </Tooltip>
            </Wrapper>
        </Form>
    )
}