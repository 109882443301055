import axios from "axios";
import { AGGREGATE_DATA,GORGIAS_LAMBA_API } from "../constants/Endpoints"
export const getAggregateDataForSearch = async (ticketDetails,queryText,operation,agentDetails,userEmail,prismToken,orgId,type=undefined,body={})=>{
    const payload = {
        ...body,
        question: queryText,
        operation: operation,
        ticketId: ticketDetails?.id,
        ticketTags: ticketDetails?.tags,
        agentId: agentDetails?.id,
        groupIds: [],
        agentUserIds: [],
        agent: userEmail,
        role: agentDetails?.role,
        subject: "",
        description: "",
        conversations:[]
        }
        if (type){
            payload['type'] = type
        }
    try{
        const response = await axios.post(`${AGGREGATE_DATA}?orgid=${orgId}`, 
          payload,
          {
            headers: {
            "Authorization": `Bearer ${prismToken}`
          }
        }
        );
        return response
      }
      catch(error){
        console.error("error in gpt article answer",error)
        throw error;
      }
}


export const gptOneTouchAnswersForSearchResearch = async (operation,prismToken,subDomain,ticketingSystem,ticketDetails,orgId,userEmail,userId,searchQuery) =>{
  const requestBody = {
    isUserEmailAvailable: true,
    subdomain: subDomain,
    ticketingSystem: ticketingSystem,
    operation: operation,
    user_email: userEmail,
    subject: ticketDetails?.subject,
    ticketId: ticketDetails?.id,
    user_id: userId,
    customer_id: ticketDetails?.customer?.id ?? "",
    orgId: orgId,
    fromApp :true,
    search: true,
    searchQuery : searchQuery
  }

  try{
    const response = await axios.post(`${GORGIAS_LAMBA_API}?orgid=${orgId}`, 
      requestBody,
      {
        headers: {
        "Authorization": `Bearer ${prismToken}`
      }
    }
    );
    return response
  }
  catch(error){
    console.error("error in making gpt one touch call for search research",error)
    throw error;
  }
}