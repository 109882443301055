import { ReadylyHeader } from "../../components/ReadylyHeader/ReadylyHeader";
import '../login/style'
import React, { useState} from 'react';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import debounce from 'lodash/debounce';
import { HiOutlineMail } from 'react-icons/hi';
import { FaLink } from "react-icons/fa";
import { VscKey } from "react-icons/vsc";
import { Wrapper,Form,InputField,Input,Tag,InputArea,ErrorMessage } from "../login/style";
function Onboarding (){
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [userEmail,setEmail]  = useState('');
    const [apiKey,setApiKey] = useState('')
    const [instanceUrl,setInstanceUrl] = useState('')
    const debouncedEmail = debounce((value) => setEmail(value), 500);
    const debouncedApiKey = debounce((value) => setApiKey(value), 500);
    const debouncedUrl = debounce((value) => setInstanceUrl(value), 500);

    function goToApp(e){
        e.preventDefault();
    setIsSubmitted(true);
    const base64Encode = (str) => {
        return btoa(unescape(encodeURIComponent(str)));
      };
      
      // Example usage

    if (userEmail.trim() === '' || apiKey.trim() === '' || instanceUrl.trim() === '') {
      return;
    }
    if (!(instanceUrl.includes('gorgias.com'))){
        setInstanceUrl("")
        return
    }
    const encodedString = base64Encode(`${userEmail}:${apiKey}`);
    window.open(`https://raa-onboarding-web.readyly.app/?subDomain=${instanceUrl}&apiKey=${encodedString}`,"_blank")
}
    
    return(
        <>
        <ReadylyHeader/>
        <Wrapper style={{height:"90vh",display:"flex",flexDirection:"column",justifyContent:"space-around"}}>
            <h3> Sorry, Your are not integrated with us. Please fill the following details , we need this information for onboarding!</h3>
            <Form style={{marginBottom : "0",padding:"2.25rem 1.5rem"}}>
                <InputField>
                    <Tag>Enter Your Admin email  <span style={{color:"red"}}>*</span></Tag>
                        <InputArea>
                            <Input
                            type="text"
                            placeholder="Enter Email"
                            onChange={(e) => debouncedEmail(e.target.value)}
                            required
                            />
                            <HiOutlineMail style={{ color: '#333333'}}/>
                        </InputArea>
                        {isSubmitted && userEmail.trim() === '' && (
                        <ErrorMessage>Please enter your email.</ErrorMessage>
                        )}
                </InputField>
                <InputField>
                    <Tag>Enter your api key <span style={{color:"red"}}>*</span></Tag>
                        <InputArea>
                            <Input
                            type="text"
                            placeholder="Enter api key"
                            onChange={(e) => debouncedApiKey(e.target.value)}
                            required
                            />
                            <VscKey style={{ color: '#333333'}}/>
                        </InputArea>
                        {isSubmitted && apiKey.trim() === '' && (
                        <ErrorMessage>Please enter your api key.</ErrorMessage>
                        )}
                </InputField>
                <InputField>
                    <Tag>Enter your instance url <span style={{color:"red"}}>*</span></Tag>
                        <InputArea>
                            <Input
                            type="text"
                            placeholder="subdomain.gorgias.com"
                            onChange={(e) => debouncedUrl(e.target.value)}
                            required
                            />
                            <FaLink style={{ color: '#333333'}}/>
                        </InputArea>
                        {isSubmitted && instanceUrl.trim() === '' && (
                        <ErrorMessage>Please enter your url.</ErrorMessage>
                        )}
                </InputField>

                <InputField style={{display:"flex",justifyContent:"center"}}>
                    <Tooltip title="go to the app">
                        <Button variant="contained" onClick={goToApp}>Onboard</Button>
                    </Tooltip>
                </InputField>
            </Form>
        </Wrapper>
        </>
        
    )

}
export default Onboarding