
import { styled } from '@mui/system';


export const Wrapper = styled('div')({
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width:"20%",
});

export const Options = styled("span")(
  {

    width: "fit-content",
    padding: "0px 1rem",
    textDecoration: "none",
    color: "rgb(255, 255, 255)",
    fontSize: "18px",
    cursor:"pointer",
  }
)
    