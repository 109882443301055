import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircularProgress from '@mui/material/CircularProgress';
import { Modal, Button } from '@mui/material';
import React, { useState } from 'react';
import ModalComponent from '../Modal/modal'
import { ItemHeader,NoItems,TitleContainter,CustomButton,TicketContainer,ArticleTitle } from './styles';


export const Workflows = ({workflows,isloading,userEmail,ticketDetails,orgId,prismToken,renderType,subDomain,ticketingSystem,agentDetails,queryText})=>{

    const [open, setOpen] = useState(false);
    const [modalUrl, setModalUrl] = useState('');
    const handleOpenModal = (id, title) => {
        const url = encodeURI(
          `https://help.readyly.app/agent/ai-agent?workflowId=${id}&workflowName=${title}&ticketId=${ticketDetails?.id}`
        );
        setModalUrl(url);
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
        setModalUrl('');
      };
    
      const handleProceed = () => {
        window.open(modalUrl, '_blank');
        handleClose();
      };
    return (
        <>
       

        {workflows.length ? <Accordion style={{border:"none"}}>
        <AccordionSummary 
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
            <TitleContainter>
                <ItemHeader>Suggested Workflows</ItemHeader> 
                {isloading ? <NoItems><CircularProgress size = {20}/>
                </NoItems> : <NoItems>{workflows.length} Workflows</NoItems> }
            </TitleContainter>
          
        </AccordionSummary>
        <AccordionDetails>
            <div>
                {workflows.map((item)=>{
                    return<TicketContainer style={{flexDirection:"column",marginBottom:"1rem",alignItems:"flex-start"}}>
                                <ArticleTitle style={{textAlign:"center"}}>{item?.meta?.title}</ArticleTitle>
                                <CustomButton style={{width:"30%"}} onClick={() => handleOpenModal(item?.meta?.id, item?.meta?.title)}>Execute</CustomButton>
                        </TicketContainer>

                })}
            </div>
        </AccordionDetails>
      </Accordion> :  <></>}

      <ModalComponent
        open={open}
        handleClose={handleClose}
        handleProceed={handleProceed}
        title="Open Workflow"
        description="Do you want to proceed to the workflow in a new tab?"
      />

        </>
    )
}