import { ReadylyHeader } from "../../components/ReadylyHeader/ReadylyHeader"
import  {Wrapper, Tag ,Form,Checkbox}  from "../login/style.js"
import Tooltip from '@mui/material/Tooltip';
import { BiCheck } from 'react-icons/bi';
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import './dataimportstyles.css'
import {  useLocation,useNavigate  } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {getAgentDetails, getTicketDetails, initateDataImport} from '../../api.js'
import CircularIndeterminate from '../../components/circularspinner/circularspinner.js'

function Dataimport (){
    const [importTickets, setImportTickets] = useState(false);
    const dataImportLoading = useSelector((state)=>state.auth.dataImportLoading);
    const dataImportDetails = useSelector((state)=>state.auth.dataImportDetails);
    const ticketId = useSelector((state)=>state.ticket.ticketId);
    const email = useSelector((state)=>state.auth.email);
    const agentDetails = useSelector((state)=>state.auth.agentDetails);
    const agentDetailsLoading = useSelector((state)=>state.auth.agentDetailsLoading);



    const [importArticles, setImportArticles] = useState(false);
    const [importMacros, setImportMacros] = useState(false);
    const [createTriggers, setCreateTriggers] = useState(false);

    
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const subDomain = queryParams.get('subDomain');
    const orgId = queryParams.get('orgId');
    const prismToken = queryParams.get('prismToken');
    const sourceAccessToken = queryParams.get('sourceAccessToken');
    const ticketingSystem = queryParams.get('ticketingSystem');
    const paramUserEmail = queryParams.get('email');
    const paramTicketId = queryParams.get('ticketId');

    const navigate = useNavigate();

    const dispatch = useDispatch();

    function submitDataImport(){
        const payload = {
            "accessToken": prismToken,
            "sourceAccessToken": sourceAccessToken, 
            "orgId": orgId,
            "subdomain": subDomain,
            "ticketingSystem": ticketingSystem,
            "ticketsVolume": "2000",
            "importTickets": importTickets,
            "importArticles": importArticles,
            "importMacros": importMacros, 
            "createTriggers": createTriggers, 
        }
        dispatch(initateDataImport(payload,orgId,prismToken,subDomain))

    }

    useEffect(()=>{
        if (!ticketId && paramTicketId && prismToken){
            dispatch(getTicketDetails(paramTicketId, subDomain,ticketingSystem,orgId,prismToken));
        }
       
    },[ticketId]);
    useEffect(()=>{
        if (!email && paramUserEmail && prismToken){
            dispatch(getAgentDetails(paramUserEmail,ticketingSystem,orgId,prismToken));
        }

    },[agentDetails])

    useEffect(()=>{
        if (dataImportDetails?.status === 'completed'){
            navigate(`/app/?subDomain=${subDomain}&ticketId=${ticketId}&email=${email}&ticketingSystem=${ticketingSystem}`);
        }
    },[dataImportDetails])
    const isAdmin = agentDetails?.role === 'admin';
    const isValidUser = agentDetails?.verified ?? false;

    const loader = dataImportLoading || agentDetailsLoading;
    return (
        <>
        <ReadylyHeader/>

        {loader ? <CircularIndeterminate/> : (isAdmin && isValidUser) ?
        <Wrapper style={{display:"flex",height:"90vh",justifyContent:"space-evenly"}}>
            <h3 style={{fontSize:"1rem"}}>Thank you for choosing Readyly! We have onboarded. We need to import some data from your ticketing system instance. Please select the following</h3>

            <Form style={{display:"flex",flexDirection:"column",justifyContent:"space-between", boxShadow: "15px 15px 30px rgb(209, 217, 230),-15px -15px 30px rgb(209, 217, 230)",minHeight:"60%",minWidth:"25%",borderRadius:"10px 10px"}}>
            {/* <div style={{backgroundColor:" rgb(42, 67, 124)",height:"1rem",margin:"0",width:"100%"}}>

            </div> */}
            <h3 style={{textAlign:"center"}}>Data Import</h3>
            

            <div style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                <Tooltip title="We will digest the last 2000 tickets to be able to look for similar tickets and hasten resolution." placement="top-start" style={{display:"flex",alignItems : "center"}}>
                    <Checkbox onClick={() => setImportTickets(!importTickets)}>
                        <BiCheck
                        style={{
                            filter: `opacity(${importTickets ? 1 : 0})`,
                            transition: 'all 0.25s ease'
                        }}
                        />
                    </Checkbox>
                    <Tag style={{ marginLeft: '1.5rem',fontSize:"1rem" }}>Import Tickets</Tag>
                </Tooltip>
            </div>
            <div style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                <Tooltip title="We will digest your knowledge base articles to be able to provide relevant responses to questions raised by the user" placement="right-start" style={{display:"flex",alignItems : "center"}}>
                    <Checkbox onClick={() => setImportArticles(!importArticles)}>
                            <BiCheck
                            style={{
                                filter: `opacity(${importArticles ? 1 : 0})`,
                                transition: 'all 0.25s ease'
                            }}
                            />
                    </Checkbox>
                    <Tag style={{ marginLeft: '1.5rem',fontSize:"1rem" }}>Import Articles</Tag>
                </Tooltip>
            </div>
            <div style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                <Tooltip title="We will digest your macros to filter your collection and provide the ones most relevant to this ticket." placement="right-start" style={{display:"flex",alignItems : "center"}}>
                    <Checkbox onClick={() => setImportMacros(!importMacros)}>
                                <BiCheck
                                style={{
                                    filter: `opacity(${importMacros ? 1 : 0})`,
                                    transition: 'all 0.25s ease'
                                }}
                                />
                    </Checkbox>
                    <Tag style={{ marginLeft: '1.5rem',fontSize:"1rem" }}>Import Macros</Tag>
                </Tooltip>
            </div>
            <div style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                <Tooltip title="We will create triggers that will talk to our systems to update us when a ticket is closed." style={{display:"flex",alignItems : "center"}}>
                    <Checkbox onClick={() => setCreateTriggers(!createTriggers)}>
                                <BiCheck
                                style={{
                                    filter: `opacity(${createTriggers ? 1 : 0})`,
                                    transition: 'all 0.25s ease'
                                }}
                                />
                    </Checkbox>
                    <Tag style={{ marginLeft: '1.5rem',fontSize:"1rem" }}>Create Triggers</Tag>
                </Tooltip>
            </div>

            <Button style={{width:"20%", alignSelf:"center"}}variant="contained" onClick={submitDataImport}>Submit</Button>

            </Form>
        </Wrapper> :

        <Wrapper style={{display:"flex",height:"90vh",justifyContent:"space-evenly"}}>
            {!isAdmin ?
            <h3 style={{fontSize:"1rem"}}>Sorry , We can't intiate dataImport for you as your not Admin</h3> : <h3 style={{fontSize:"1rem"}}>Sorry , We can't intiate dataImport for you as your not a Verfied User</h3>}
        </Wrapper>
}

        </>
    )
}
export default Dataimport