import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircularProgress from '@mui/material/CircularProgress';
import { CustomButton, TitleContainter, ItemHeader, NoItems, ArticleTitleContainer, ArticleTitle, ArticleTitleTag, ArticleContainer, ArticleActionsContainer,GPTAnswers } from './styles';
import { setButtonLabels, setLoading,setArticleResponse } from '../../redux/actions/ticketActions';
import {  setSearchArticlesButtonLabels, setSearchArticlesLoading,setSearchArticleResponse } from '../../redux/actions/searchActions';
import { getAggregateDataForTicket } from '../../apis/ticketResearch';
import { getAggregateDataForSearch } from '../../apis/SearchResearch';
import { updateEvent } from '../../api';
import {getGPTAnswer} from '../../redux/actions/ticketActions'
import {getGPTAnswerForSearch} from '../../redux/actions/searchActions'

export const RelevantArticles = ({ relevantArticles, isLoading, buttonLabels, articleResponses,loading, renderType, ticketDetails, userEmail, agentDetails, prismToken, orgId, queryText , ticketingSystem ,subDomain}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const articleAnswerLoading = useSelector((state) => state.ticket.gptAnswerForArticlesLoading);
    const articleAnswer = useSelector((state) => state.ticket.gptAnswerForArticles);
    const searchAnswerLoading = useSelector((state) => state.search.gptAnswerForArticlesLoading);
    const searchArticleAnswer = useSelector((state) => state.search.gptAnswerForArticles);
    

    const gptAnswerForArticlesLoading = renderType === 'ticket' ? articleAnswerLoading : searchAnswerLoading;
    const gptAnswerforArticles = renderType === 'ticket' ? articleAnswer : searchArticleAnswer;

    const updateLoadingState = (aid,isLoading) => dispatch(renderType === "ticket" ? setLoading({ [aid]: isLoading }) : setSearchArticlesLoading({ [aid]: isLoading }));
    const updateResponseState = (response,aid,type) => {
        const action = renderType === "ticket" ? setArticleResponse : setSearchArticleResponse;
            dispatch(action({
                aid,
                type,
                response: response.data.answer
            }));
        };
    const setLabels = (aid,type) => {
        const actionType = renderType === "ticket" ? setButtonLabels : setSearchArticlesButtonLabels;
        const label = type === 'gptAnswer' ? 'View Answer' : 'View Summary';
        
        dispatch(actionType({
            [aid]: {
            ...buttonLabels[aid],
            [type]: label
            }
        }));
        };

    const handleClick = async (aid, type, title, source) => {
        updateLoadingState(aid,type)
        // Simulate an API call
        const payload = {
            "aid": aid,
            "source": source ?? "",
        };
        const operationType = type === 'gptAnswer' ? 'answer' : 'summarize';
        const functionToCall = renderType === "ticket"
            ? getAggregateDataForTicket(ticketDetails, "article_operation", agentDetails, userEmail, prismToken, orgId, operationType, payload)
            : getAggregateDataForSearch(ticketDetails, queryText, "article_operation", agentDetails, userEmail, prismToken, orgId, operationType, payload);

        try {
            const response = await functionToCall;

            if (response.status === 200) {
                    updateResponseState(response,aid,type);
                    setLabels(aid,type)
                    updateLoadingState(aid,null);

                navigate('/app/article', {
                    state: {
                        title: title,
                        source: source,
                        articleContent: response.data.answer,
                        renderType: 'article',
                        ticketDetails: ticketDetails,
                        orgId:orgId,
                        prismToken:prismToken,
                        userEmail:userEmail,
                        agentDetails:agentDetails,
                        ticketingSystem : ticketingSystem,
                        subDomain : subDomain
                    }
                    
                });
            }
        } catch (error) {
            console.error('Error fetching article data:', error);
            updateLoadingState(aid,null);
        }
    };


    const handleGPTClick = ()=>{
        dispatch(
            renderType === "ticket"
              ? getGPTAnswer(subDomain, ticketingSystem, ticketDetails, "gptanswerforarticles", agentDetails, userEmail, prismToken, orgId, "ARTICLES")
              : getGPTAnswerForSearch(subDomain, ticketingSystem, queryText, ticketDetails, "gptanswerforarticles", agentDetails, userEmail, prismToken, orgId, "ARTICLES")
          );
      }
    const handleViewClick = (aid, type, title, source) => {
        navigate('/app/article', {
            state: {
                title,
                source,
                articleContent: articleResponses[aid]?.[type] || 'Content not available',
                renderType: 'article',
                ticketDetails: ticketDetails,
                orgId:orgId,
                prismToken:prismToken,
                userEmail:userEmail,
                agentDetails:agentDetails,
                ticketingSystem : ticketingSystem,
                subDomain : subDomain
            },
            
        });
    };

    const handleGPTAnswer = () =>{
        navigate('/app/article', {
          state: {
              title: "",
              source: "source",
              articleContent: gptAnswerforArticles,
              renderType: 'gptArticlesAnswer',
              ticketDetails: ticketDetails,
              orgId:orgId,
              prismToken:prismToken,
              userEmail:userEmail,
              agentDetails:agentDetails,
              ticketingSystem : ticketingSystem,
              subDomain : subDomain
          }
          
      });
      }
    const handleClickArticleSource = (sourceLink)=>{
         window.open(sourceLink, "_blank")
        dispatch(updateEvent("articleSource","",ticketDetails?.id,queryText,userEmail,orgId,prismToken))
    }
    const filteredArticles = relevantArticles.filter((article) => article?.response?.title && article?.engine === 'gpt');

    return (
        <>
        { filteredArticles.length ? 
        <Accordion style={{ border: "none" }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
            >
                <TitleContainter>
                    <ItemHeader>Relevant Articles</ItemHeader>
                    {isLoading ? <NoItems><CircularProgress size={20} /></NoItems> : <NoItems>{filteredArticles.length} Articles</NoItems>}
                </TitleContainter>
            </AccordionSummary>
            <AccordionDetails>
                {filteredArticles.map((item) => (
                    <ArticleContainer key={item.response.aid}>
                        <ArticleTitleContainer>
                            <ArticleTitle>
                                {item?.response?.title}
                            </ArticleTitle>
                            {typeof item?.response?.formattedText === 'string' ? <ArticleTitleTag>Article</ArticleTitleTag> : null}
                        </ArticleTitleContainer>
                        <ArticleActionsContainer>
                            {loading[item.response.aid] === 'gptAnswer' ? <CircularProgress size={20} /> : <CustomButton onClick={() => buttonLabels[item.response.aid]?.['gptAnswer'] === 'View Answer' ? handleViewClick(item.response.aid, 'gptAnswer', item?.response?.title, item?.response?.source) : handleClick(item.response.aid, 'gptAnswer', item?.response?.title, item?.response?.source)}>
                                {buttonLabels[item.response.aid]?.gptAnswer || 'GPT Answer'}
                            </CustomButton>}
                            {loading[item.response.aid] === 'summary' ? <CircularProgress size={20} /> : <CustomButton onClick={() => buttonLabels[item.response.aid]?.['summary'] === 'View Summary' ? handleViewClick(item.response.aid, 'summary', item?.response?.title, item?.response?.source) : handleClick(item.response.aid, 'summary', item?.response?.title, item?.response?.source)}>
                                {buttonLabels[item.response.aid]?.summary || 'Summary'}
                            </CustomButton>}
                            {item?.response?.source && <CustomButton onClick={() => handleClickArticleSource(item?.response?.source)}>Source</CustomButton>}
                        </ArticleActionsContainer>
                    </ArticleContainer>
                ))}
                 {gptAnswerForArticlesLoading ? <CircularProgress size={20} /> : gptAnswerforArticles.length ? <GPTAnswers onClick={()=>(handleGPTAnswer())}>GPT Answer : {gptAnswerforArticles}</GPTAnswers> : <CustomButton onClick={()=>handleGPTClick()}>GPT Answer For Relevant Articles</CustomButton>}
            </AccordionDetails>
        </Accordion> : <></>}
        </>
    );
};
