import {SAVE_TICKET_DETAILS,
    SAVE_TICKET_ID,
    TICKET_DETAILS_LOADING,
    
    RESEARCH_TAB_LOADING,
    RESEARCH_CURRENT_TAB,
  }  from '../actions/authActions';
  import {  WORKFLOWS,
    WORKFLOWS_LOADING,
    GPT_ANSWER_TICKETS,
    GPT_ANSWER_ARTICLES,
    GPT_ANSWER_MACROS,
    GPT_ANSWER_TICKETS_LOADING,
    GPT_ANSWER_ARTICLES_LOADING,
    GPT_ANSWER_MACROS_LOADING,
    SIMILAR_TICKETS_LOADING,
    SAVE_SIMILAR_TICKETS,
    RELEVANT_ARTICLES_LOADING,
    SAVE_RELEVANT_ARTICLES,
    SUGGESTED_MACROS_LOADING,
    SAVE_SUGGESTED_MACROS,
  } from '../actions/ticketActions'

    const initialState = {
        ticketId:'',
        ticketDetails: {},
        ticketDetailsLoading : false,
        macrosloading : false,
        articlesloading: false,
        ticketsloading: false,
        researchTabloading: false,
        researchCurrentTab : 0,
        similarTickets : [],
        relevantArticles : [],
        suggestedMacros : [],
        workflows : [],
        workflowsLoading : false,
        gptAnswerForTickets : "",
        gptAnswerForArticles : "",
        gptAnswerForMacros : "",
        gptAnswerForTicketsLoading : false,
        gptAnswerForArticlesLoading : false,
        gptAnswerForMacrosLoading : false
      };
      
      const ticketReducer = (state = initialState, action) => {
        switch (action.type) {
          case SAVE_TICKET_ID:
            return {
              ...state,
              ticketId: action.payload
              
            };
          case TICKET_DETAILS_LOADING:
            return {
              ...state,
              ticketDetailsLoading: action.payload,
            };
          case SAVE_TICKET_DETAILS:
              return {
                ...state,
                ticketDetails: action.payload,
              };
          case SIMILAR_TICKETS_LOADING:
            return {
              ...state,
              ticketsloading: action.payload
              
            };
          case RELEVANT_ARTICLES_LOADING:
            return {
              ...state,
              articlesloading: action.payload
              
            };
          case SUGGESTED_MACROS_LOADING:
            return {
              ...state,
              macrosloading: action.payload,
            };
          case SAVE_SIMILAR_TICKETS:
              return {
                ...state,
                similarTickets: action.payload,
              };
          case SAVE_RELEVANT_ARTICLES:
            return {
              ...state,
              relevantArticles: action.payload, 
            };
          case SAVE_SUGGESTED_MACROS:
            return {
              ...state,
              suggestedMacros: action.payload,
            };
          case RESEARCH_TAB_LOADING:
            return {
              ...state,
              researchTabloading: action.payload,
            };
          case RESEARCH_CURRENT_TAB:
            return {
              ...state,
              researchCurrentTab: action.payload,
            };
          case GPT_ANSWER_TICKETS_LOADING:
            return {
              ...state,
              gptAnswerForTicketsLoading: action.payload,
            };
          case GPT_ANSWER_MACROS_LOADING:
            return {
              ...state,
              gptAnswerForMacrosLoading: action.payload,
            };
          case GPT_ANSWER_ARTICLES_LOADING:
            return {
              ...state,
              gptAnswerForArticlesLoading: action.payload,
            };
          case GPT_ANSWER_TICKETS:
            return {
              ...state,
              gptAnswerForTickets: action.payload,
          };
          case GPT_ANSWER_ARTICLES:
            return {
              ...state,
              gptAnswerForArticles: action.payload,
            };
          case GPT_ANSWER_MACROS:
            return {
              ...state,
              gptAnswerForMacros: action.payload,
            };
          case WORKFLOWS:
            return {
              ...state,
              workflows: action.payload,
            };
          case WORKFLOWS_LOADING:
            return {
              ...state,
              workflowsLoading: action.payload,
            };
      
          default:
            return state;
        }
      };
      
      export default ticketReducer;