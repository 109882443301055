// Packages:
import { styled } from '@mui/material/styles'


// Exports:
export const Subtitle = styled('div')({
  marginTop: "3.5rem",
  marginBottom: "2.188rem",
  fontSize: "16px",
  color: "#333333"
})

export const Form = styled('div')({
  marginBottom: "1rem",
  padding: "1.5rem 1.5rem 1.5rem",
  fontSize: "16px",
  boxShadow: "0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%)",
  background: "#ffffff",
  borderRadius: "5px"
})

export const InputField = styled('div')({
  marginBottom: "1.5rem",
})