import React, { memo } from "react";
import { Navigate, useLocation } from "react-router-dom";
import ROUTES from "../../constants/routes";
import { useSelector } from "react-redux";

const RequireAuth = ({ children }) => {
  const isAuthenticatedUser=useSelector((state)=>state.auth.isAuthenticatedUser)
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const subDomain =  queryParams.get('subDomain');


  if (!isAuthenticatedUser)
    return (
      <Navigate to={`${ROUTES.AGENT.LOGIN}?subDomain=${subDomain}`} state={{ path: `${location.pathname ?? ROUTES.INDEX}${location.search}` }} /> 
    );

  return <>{children}</>;
};

export default RequireAuth;