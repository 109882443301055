import {
    GPT_SANDBOX_LOADING,
    ADD_TO_GPT_SANDBOX,
    GPT_UNDO,
    GPT_REDO,
    REMOVE_FROM_GPT_SANDBOX,
    UPDATE_STACK,
    UPDATE_GPT_SANDBOX_CONTENT
} from '../actions/authActions';

const initialState = {
    gptSandboxLoading: false,
    sandBoxStack: {
        'gptSandBox': [{ action: "First draft", content: "" }],
        'gptSandBoxCurrentContentIndex': 0,
        'macro': [{ action: "First draft", content: "" }],
        'macroCurrentContentIndex': 0
    }
};

const sandboxReducer = (state = initialState, action) => {
    switch (action.type) {
        case GPT_SANDBOX_LOADING:
            return {
                ...state,
                gptSandboxLoading: action.payload
            };
        case ADD_TO_GPT_SANDBOX:
            const newGptSandBox = [
                ...state.sandBoxStack.gptSandBox.slice(0, state.sandBoxStack.gptSandBoxCurrentContentIndex + 1),
                action.payload
            ];
        
            return {
                ...state,
                sandBoxStack: {
                    ...state.sandBoxStack,
                    gptSandBox: newGptSandBox,
                    gptSandBoxCurrentContentIndex: newGptSandBox.length - 1,
                }
            };
        case UPDATE_STACK:
             return {
                ...state,
                sandBoxStack:{
                    ...state.sandBoxStack,
                    gptSandBox:[...action.payload]
                }
             };         
        case GPT_UNDO:
            return {
                ...state,
                sandBoxStack: {
                    ...state.sandBoxStack,
                    gptSandBoxCurrentContentIndex: Math.max(state.sandBoxStack.gptSandBoxCurrentContentIndex - 1, 0)
                }
            };
        case GPT_REDO:
            return {
                ...state,
                sandBoxStack: {
                    ...state.sandBoxStack,
                    gptSandBoxCurrentContentIndex: Math.min(state.sandBoxStack.gptSandBoxCurrentContentIndex + 1, state.sandBoxStack.gptSandBox.length - 1)
                }
            };
        case REMOVE_FROM_GPT_SANDBOX:
            return {
                ...state,
                sandBoxStack: {
                    ...state.sandBoxStack,
                    gptSandBox: state.sandBoxStack.gptSandBox.slice(1)
                }
            };
            case UPDATE_GPT_SANDBOX_CONTENT:
                const { index, content } = action.payload;
                return {
                  ...state,
                  sandBoxStack: {
                    ...state.sandBoxStack,
                    gptSandBox: state.sandBoxStack.gptSandBox.map((item, idx) =>
                      idx === index ? { ...item, content : content } : item
                    )
                  }
                };
        default:
            return state;
    }
};

export default sandboxReducer;
