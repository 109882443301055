
import { SEARCH_QUERY_WORKFLOWS,
    SEARCH_QUERY_WORKFLOWS_LOADING,
    SEARCH_QUERY_GPT_ANSWER_TICKETS_LOADING,
    SEARCH_QUERY_GPT_ANSWER_ARTICLES_LOADING,
    SEARCH_QUERY_GPT_ANSWER_MACROS_LOADING,
    SEARCH_QUERY_GPT_ANSWER_TICKETS,
    SEARCH_QUERY_GPT_ANSWER_ARTICLES,
    SEARCH_QUERY_GPT_ANSWER_MACROS,
    SEARCH_QUERY_SIMILAR_TICKETS_LOADING,
    SEARCH_QUERY_SAVE_SIMILAR_TICKETS,
    SEARCH_QUERY_RELEVANT_ARTICLES_LOADING,
    SEARCH_QUERY_SAVE_RELEVANT_ARTICLES,
    SEARCH_QUERY_SUGGESTED_MACROS_LOADING,
    SAVE_SEARCH_QUERY,
    SEARCH_QUERY_SAVE_SUGGESTED_MACROS,SET_SEARCH_QUERY_ARTICLES_LOADING
  } from '../actions/searchActions'

    const initialState = {
        searchMacrosloading : false,
        searchArticlesloading: false,
        searchTicketsloading: false,
        searchWorkflowsLoading : false,
        searchSimilarTickets : [],
        searchRelevantArticles : [],
        searchSuggestedMacros : [],
        searchWorkflows : [],
        searchQuery : "",
        gptAnswerForTickets : "",
        gptAnswerForArticles : "",
        gptAnswerForMacros : "",
        gptAnswerForTicketsLoading : false,
        gptAnswerForArticlesLoading : false,
        gptAnswerForMacrosLoading : false
      };
      
      const searchReducer = (state = initialState, action) => {
        switch (action.type) {
          case SEARCH_QUERY_SIMILAR_TICKETS_LOADING:
            return {
              ...state,
              searchTicketsloading: action.payload
              
            };
          case SAVE_SEARCH_QUERY:
            return {
              ...state,
              searchQuery: action.payload
              
            };
          case SEARCH_QUERY_RELEVANT_ARTICLES_LOADING:
            return {
              ...state,
              searchArticlesloading: action.payload
              
            };
          case SEARCH_QUERY_SUGGESTED_MACROS_LOADING:
            return {
              ...state,
              searchMacrosloading: action.payload,
            };
          case SEARCH_QUERY_SAVE_SIMILAR_TICKETS:
              return {
                ...state,
                searchSimilarTickets: action.payload,
              };
          case SEARCH_QUERY_SAVE_RELEVANT_ARTICLES:
            return {
              ...state,
              searchRelevantArticles: action.payload, 
            };
          case SEARCH_QUERY_SAVE_SUGGESTED_MACROS:
            return {
              ...state,
              searchSuggestedMacros: action.payload,
            };
          case SEARCH_QUERY_GPT_ANSWER_TICKETS_LOADING:
            return {
              ...state,
              gptAnswerForTicketsLoading: action.payload,
            };
          case SEARCH_QUERY_GPT_ANSWER_MACROS_LOADING:
            return {
              ...state,
              gptAnswerForMacrosLoading: action.payload,
            };
          case SEARCH_QUERY_GPT_ANSWER_ARTICLES_LOADING:
            return {
              ...state,
              gptAnswerForArticlesLoading: action.payload,
            };
          case SEARCH_QUERY_GPT_ANSWER_TICKETS:
            return {
              ...state,
              gptAnswerForTickets: action.payload,
          };
          case SEARCH_QUERY_GPT_ANSWER_ARTICLES:
            return {
              ...state,
              gptAnswerForArticles: action.payload,
            };
          case SEARCH_QUERY_GPT_ANSWER_MACROS:
            return {
              ...state,
              gptAnswerForMacros: action.payload,
            };
          case SEARCH_QUERY_WORKFLOWS_LOADING:
            return {
              ...state,
              searchWorkflowsLoading: action.payload,
            };
          case SEARCH_QUERY_WORKFLOWS:
            return {
              ...state,
              searchWorkflows: action.payload,
            };
          
          default:
            return state;
        }
      };
      
      export default searchReducer;