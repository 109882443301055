export const combinedActionMap = {
    "Tickets": {
        operation: "gptanswerfortickets",
        event: "oneTouchTicketAnswer"
    },
    "Articles": {
        operation: "gptanswerforarticles",
        event: "oneTouchKbAnswer"
    },
    "Macros": {
        operation: "gptanswerformacros",
        event: "oneTouchMacroAnswer"
    }
}
