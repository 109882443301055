import React, { useEffect} from 'react';

import { AgentAssistHeader } from '../../components/AgentAssisHeader/AgentAssisHeader.js';
import {Form} from '../login/style.js';
import { useSelector,useDispatch } from 'react-redux';
import {getTicketDetails,getAgentDetails,getOrgData} from '../../api.js'
import CircularIndeterminate from '../../components/circularspinner/circularspinner'
import {  useLocation } from 'react-router-dom';
import { Wrapper} from '../../components/AgentAssisHeader/styles.js'
import {TicketComponent}  from '../../components/TicketComponent/TicketComponent.js';
import GPTSandbox from './GPTSandbox.js'


const ComponetsStyle = {flexDirection:"column",width:"100%",height:"85vh",position:"relative",top:"7rem"}

export const AgentAssist = ()=>{
    const agentDetails = useSelector((state)=>state.auth.agentDetails);
    const orgDetails =  useSelector((state)=>state.auth.orgDetails);
    const ticketDataloading = useSelector((state)=>state.ticket.ticketDetailsLoading);
    const orgDetailsLoading = useSelector((state)=>state.auth.orgDetailsLoading);
    const agentDetailsLoading = useSelector((state)=>state.auth.agentDetailsLoading);
    const ticketId = useSelector((state)=>state.ticket.ticketId);
    const subDomain = useSelector((state)=>state.auth.domain);
    const email =  useSelector((state)=>state.auth.email);
    const ticketingSystem = orgDetails?.ticketing?.platform;
    const prismToken = useSelector((state)=>state.auth.prismToken);
    const dispatch = useDispatch();
    

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    useEffect(()=>{
        const paramSubdomain = queryParams.get('subDomain');
        if (!subDomain && paramSubdomain ){
            dispatch(getOrgData(paramSubdomain));
        }
    },[])
    useEffect(()=>{
        const paramUserEmail = email || queryParams.get('email');
        const paramTicketId = ticketId || queryParams.get('ticketId');
        const paramticketingSystem = ticketingSystem || queryParams.get("ticketingSystem") ;
        if (!email && paramUserEmail && prismToken && orgDetails?._id){
            dispatch(getAgentDetails(paramUserEmail,paramticketingSystem,orgDetails?._id,prismToken));
        }
        if (!ticketId && paramTicketId && prismToken && orgDetails?._id){
            dispatch(getTicketDetails(paramTicketId,subDomain,paramticketingSystem,orgDetails?._id,prismToken));
        }

    },[orgDetails])


    const isValid = Object.keys(orgDetails).length > 0 && agentDetails?.verified;
    const loading = ticketDataloading || orgDetailsLoading || agentDetailsLoading;
    const isTicketIdValid = ticketId;
    const isAdmin = Object.keys(agentDetails).length > 0 && agentDetails?.role.toLowerCase() === "admin";

    return (
        <>
        <AgentAssistHeader isAdmin = {isAdmin} isTicketIdValid = {isTicketIdValid} subDomain={subDomain} ticketId={ticketId} email={email} ticketingSystem={ticketingSystem} orgId={orgDetails?._id} prismToken={prismToken}/>
        
        {loading ? <CircularIndeterminate/> : isValid ? <Wrapper style={ComponetsStyle}><TicketComponent subDomain={subDomain} ticketingSystem={ticketingSystem} prismToken={prismToken} ticketId={ticketId} orgId={orgDetails?._id}/>
        <GPTSandbox isTicketIdValid={isTicketIdValid} agentDetails= {agentDetails} userEmail = {email} /> </Wrapper> : <div >
            <Form style={{display:"flex",justifyContent:"center",alignItems:"center",height:"50vh"}}>

            {Object.keys(orgDetails).length > 0 ?  <h3> Invalid Email Id</h3> : <h3> Invalid Sub Domain</h3>}
            </Form>
        </div>}

        
        </>
        

        
    )
}