import React, { createContext, useContext,memo } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ROUTES from '../constants/routes.js';
import { useDispatch } from 'react-redux';
import { saveAuthenticatedUser } from '../redux/actions/authActions.js';

const AuthContext = createContext();
export const AuthProvider =memo( ({ children }) => {
  const navigate = useNavigate();
  const dispatch=useDispatch()

  const getCurrentUser = () => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        // console.log(user);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const login = async (username, password, changeLoading,redirectPath) => {
    Auth.signIn(username, password, changeLoading)
      .then((user) => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          const newPassword = prompt('New Password Required');
          Auth.completeNewPassword(
            user, // the Cognito User Object
            newPassword
          )
            .then((user) => {
              // at this time the user is logged in if no MFA required
              dispatch(saveAuthenticatedUser(true))
              navigate(redirectPath,{replace:true});
              changeLoading();
            })
            .catch((e) => {
              toast.error(e.message);
              navigate(ROUTES.AGENT.LOGIN,{state:{path:redirectPath}});
              changeLoading();
            });
        } else {
            dispatch(saveAuthenticatedUser(true))
            navigate(redirectPath,{replace:true});
            changeLoading();
        }
      })
      .catch((e) => {
        toast.error(e.message);
        changeLoading();
        navigate(ROUTES.AGENT.LOGIN,{state:{path:redirectPath}});
      });
  };
  const sendCode = (username) => {
    Auth.forgotPassword(username)
      .then((data) => {
        //console.log(data)
        toast.success('Code sent successfully');
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const changePassword = (oldpassword, newpassword) => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, oldpassword, newpassword);
      })
      .then((data) => {
        toast.success('Password changed successfuly');
        
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const validatePassword = (username, code, new_password) => {
    Auth.forgotPasswordSubmit(username, code, new_password)
      .then((data) => {
        toast.success('Password changed successfully');
        navigate(ROUTES.AGENT.LOGIN);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const logout = () => {
    signOut();
  };
  async function signOut() {
    try {
      await Auth.signOut();
      window.location.reload(true);
      navigate('/');
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        validatePassword,
        sendCode,
        changePassword,
        getCurrentUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
});

export const useAuth =() => {
  return useContext(AuthContext);
};
