import { toggleSnackbar } from "./authActions";
import {gptOneTouchAnswersForTicketResearch,getAggregateDataForTicket,getWorkflows} from '../../apis/ticketResearch'
import {RESEARCH_TAB_LOADING} from './authActions'
export const WORKFLOWS = "WORKFLOWS";
export const WORKFLOWS_LOADING = "WORKFLOWS_LOADING";
export const GPT_ANSWER_TICKETS = "GPT_ANSWER_TICKETS";
export const GPT_ANSWER_ARTICLES = "GPT_ANSWER_ARTICLES";
export const GPT_ANSWER_MACROS = "GPT_ANSWER_MACROS";
export const GPT_ANSWER_TICKETS_LOADING = "GPT_ANSWER_TICKETS_LOADING";
export const GPT_ANSWER_ARTICLES_LOADING = "GPT_ANSWER_ARTICLES_LOADING";
export const GPT_ANSWER_MACROS_LOADING = "GPT_ANSWER_MACROS_LOADING";
export const SIMILAR_TICKETS_LOADING = "SIMILAR_TICKETS_LOADING";
export const SAVE_SIMILAR_TICKETS = "SAVE_SIMILAR_TICKETS";
export const RELEVANT_ARTICLES_LOADING = "RELEVANT_ARTICLES_LOADING";
export const SAVE_RELEVANT_ARTICLES = "SAVE_RELEVANT_ARTICLES";
export const SUGGESTED_MACROS_LOADING = "SUGGESTED_MACROS_LOADING";
export const SAVE_SUGGESTED_MACROS = "SAVE_SUGGESTED_MACROS";
export const SET_LOADING = 'SET_LOADING';
export const SET_BUTTON_LABELS = "SET_BUTTON_LABELS";
export const SET_ARTICLE_RESPONSE = 'SET_ARTICLE_RESPONSE';

export const startLoading = (dispatch, type) => dispatch({ type, payload: true });
export const stopLoading = (dispatch, type) => dispatch({ type, payload: false });

export const handleGPTResponse = (dispatch, buttonType, data) => {
  const actionTypeMap = {
    "Articles": GPT_ANSWER_ARTICLES,
    "Tickets": GPT_ANSWER_TICKETS,
    "Macros": GPT_ANSWER_MACROS
  };
  dispatch({
    type: actionTypeMap[buttonType],
    payload: data
  });
};

export const setLoading = (payload) => ({
    type: SET_LOADING,
    payload
  });

export const setButtonLabels = (payload) => ({
type: SET_BUTTON_LABELS,
payload
});

export const setArticleResponse = (payload) => ({
    type: SET_ARTICLE_RESPONSE,
    payload
  });
  
export const clearGPTAnswersForResearch = () =>{
    return async (dispatch) =>{
      dispatch({
        type:GPT_ANSWER_TICKETS,
        payload:""
  
      })
      dispatch({
        type:GPT_ANSWER_ARTICLES,
        payload:""
  
      })
      dispatch({
        type:GPT_ANSWER_MACROS,
        payload:""
  
      })
    }
  }

  export const clearResearch = () =>{

    return async (dispatch) =>{
      dispatch({
        type:SAVE_SIMILAR_TICKETS,
        payload:[]
  
      })
      dispatch({
        type:SAVE_RELEVANT_ARTICLES,
        payload:[]
  
      })
      dispatch({
        type:SAVE_SUGGESTED_MACROS,
        payload:[]
  
      })
    }
  }


export const getGPTAnswer = (subDomain, ticketingSystem, ticketDetails, operation, agentDetails, userEmail, prismToken, orgId, answerType) => {
    const actionTypeLoading = `GPT_ANSWER_${answerType.toUpperCase()}_LOADING`;
    const actionTypeSuccess = `GPT_ANSWER_${answerType.toUpperCase()}`;
    const snackbarMessage = `Failed to get gpt answer for ${answerType.toLowerCase()}`;
  
    return async (dispatch) => {
        dispatch({
            type: actionTypeLoading,
            payload: true
        });
  
        try {
            const response = await gptOneTouchAnswersForTicketResearch(operation, prismToken, subDomain, ticketingSystem, ticketDetails, orgId, userEmail, agentDetails?.id);
            if (response?.status === 200) {
                let payload = response?.data;
                dispatch({
                    type: actionTypeSuccess,
                    payload: payload
                });
            } else {
                dispatch(toggleSnackbar({
                    message: snackbarMessage,
                    status: true,
                    severity: "error",
                }));
            }
        } catch (error) {
            dispatch(toggleSnackbar({
                message: snackbarMessage,
                status: true,
                severity: "error",
            }));
        }
  
        dispatch({
            type: actionTypeLoading,
            payload: false
        });
    };
  }
  

  export const getSimilarTicketsForTicket = (ticketDetails,operation,agentDetails,userEmail,prismToken,orgId) => {
    return async (dispatch,getState) =>{
      if(getState().ticket.similarTickets.length === 0){
        dispatch({
          type:SIMILAR_TICKETS_LOADING,
          payload:true
    
        })
        const response = await getAggregateDataForTicket(ticketDetails,operation,agentDetails,userEmail,prismToken,orgId);
        if (response?.status === 200){
          dispatch({
            type: SAVE_SIMILAR_TICKETS,
            payload:response?.data?.similarTickets
          })
        }
        else{
          dispatch(toggleSnackbar({
            message: `Failed to get similar tickets`,
            status: true,
            severity: "error",
          }));
        }
    
        dispatch({
          type:SIMILAR_TICKETS_LOADING,
          payload:false
    
        })
  
      }
      
    }
  }
  // export const getAggregataForTicketResearch = (ticketDetails,agentDetails,userEmail,prismToken,orgId) => {
  //   return async (dispatch) =>{
  //     dispatch({
  //       type:RESEARCH_TAB_LOADING,
  //       payload:true
  
  //     })
  //     try{
  //       await Promise.all([
  //         dispatch(getSimilarTicketsForTicket(ticketDetails,"tickets",agentDetails,userEmail,prismToken,orgId)),
  //         dispatch(getRelevantArticlesForTicket(ticketDetails,"articles",agentDetails,userEmail,prismToken,orgId,'title_search')),
  //         dispatch(getSuggestedMacrosForTicket(ticketDetails,"macros",agentDetails,userEmail,prismToken,orgId)),
  //         dispatch(getWorkFlowsForTicket(ticketDetails,agentDetails,userEmail,prismToken,orgId))
  //       ])
  //     }
  //     catch(error){
  //       console.error("aggregatedata call for ticket failed",error);
  //       dispatch(toggleSnackbar({
  //         message: "Failed to get aggregate data for ticket research",
  //         status: true,
  //         severity: "error",
  //       }))
  //     }
      
  //     dispatch({
  //       type:RESEARCH_TAB_LOADING,
  //       payload:false
  
  //     })
  //   }
  // }
  
  export const getRelevantArticlesForTicket = (ticketDetails,operation,agentDetails,userEmail,prismToken,orgId,type=undefined) => {
    return async (dispatch,getState) =>{
  
      if(getState().ticket.relevantArticles.length === 0){
        dispatch({
          type:RELEVANT_ARTICLES_LOADING,
          payload:true
    
        })
        const response = await getAggregateDataForTicket(ticketDetails,operation,agentDetails,userEmail,prismToken,orgId,type);
        if (response?.status === 200){
          let relevantArticles = response?.data?.relevantArticles;
          relevantArticles = Array.isArray(relevantArticles) ? relevantArticles : [];
          dispatch({
            type: SAVE_RELEVANT_ARTICLES,
            payload:relevantArticles
          })
          dispatch(setButtonLabels({}))
          dispatch(setLoading({}))
        }
        else{
          dispatch(toggleSnackbar({
            message: `Failed to get relevant articles`,
            status: true,
            severity: "error",
          }));
        }
    
        dispatch({
          type:RELEVANT_ARTICLES_LOADING,
          payload:false
    
        })
  
      }
      
    }
  }
  
  export const getSuggestedMacrosForTicket = (ticketDetails,operation,agentDetails,userEmail,prismToken,orgId,type=undefined) => {
    return async (dispatch,getState) =>{
      if (getState().ticket.suggestedMacros.length === 0){
        dispatch({
          type:SUGGESTED_MACROS_LOADING,
          payload:true
    
        })
        const response = await getAggregateDataForTicket(ticketDetails,operation,agentDetails,userEmail,prismToken,orgId,type);
        if (response?.status === 200){
          dispatch({
            type: SAVE_SUGGESTED_MACROS,
            payload: response?.data?.macros
          })
        }
        else{
          dispatch(toggleSnackbar({
            message: `Failed to get suggested macros`,
            status: true,
            severity: "error",
          }));
        }
    
        dispatch({
          type:SUGGESTED_MACROS_LOADING,
          payload:false
    
        })
  
      }
    }
  }
  
  export const getWorkFlowsForTicket = (ticketDetails,agentDetails,userEmail,prismToken,orgId) => {
    return async (dispatch,getState) =>{
      if(getState().ticket.workflows.length === 0){
        dispatch({
          type:WORKFLOWS_LOADING,
          payload:true
    
        })
        try{
          const response = await getWorkflows(agentDetails,prismToken,ticketDetails?.latest_question_from_customer ?? ticketDetails?.subject,ticketDetails,orgId,userEmail);
          if (response?.status === 200){
            dispatch({
              type: WORKFLOWS,
              payload:response?.data?.data?.workflows
            })
          }
          else{
            dispatch(toggleSnackbar({
              message: `Failed to get workflows for ticket`,
              status: true,
              severity: "error",
            }));
          }
        }
        catch(error){
          console.error(`Workflows request failed`,error)
          dispatch(toggleSnackbar({
            message: `Failed to get workflows for ticket`,
            status: true,
            severity: "error",
          }));
        }
      
    
        dispatch({
          type:WORKFLOWS_LOADING,
          payload:false
    
        })
  
      }
      
    }
  }



  const getDataForTicket = (ticketDetails, operation, agentDetails, userEmail, prismToken, orgId, resultType, type = undefined) => {
    return async (dispatch, getState) => {
      let loadingType, saveType, getStateData,errorMessage;  
      switch (resultType) {
        case 'similarTickets':
          loadingType = SIMILAR_TICKETS_LOADING;
          saveType = SAVE_SIMILAR_TICKETS;
          getStateData = getState().ticket.similarTickets;
          errorMessage = 'Failed to get similar tickets';
          break;
        case 'relevantArticles':
          loadingType = RELEVANT_ARTICLES_LOADING;
          saveType = SAVE_RELEVANT_ARTICLES;
          getStateData = getState().ticket.relevantArticles;
          errorMessage = 'Failed to get relevant articles';
          break;
        case 'suggestedMacros':
          loadingType = SUGGESTED_MACROS_LOADING;
          saveType = SAVE_SUGGESTED_MACROS;
          getStateData = getState().ticket.suggestedMacros;
          errorMessage = 'Failed to get suggested macros';
          break;
        case 'workflows':
          loadingType = WORKFLOWS_LOADING;
          saveType = WORKFLOWS;
          getStateData = getState().ticket.workflows;
          errorMessage = 'Failed to get workflows for ticket';
          break;
        default:
          throw new Error('Invalid result type');
      }
      if (getStateData.length === 0) {
        dispatch({
          type: loadingType,
          payload: true
        });
  
        try {
          const response = resultType === 'workflows'
            ? await getWorkflows(agentDetails, prismToken, ticketDetails?.latest_question_from_customer ?? ticketDetails?.subject, ticketDetails, orgId, userEmail)
            : await getAggregateDataForTicket(ticketDetails, operation, agentDetails, userEmail, prismToken, orgId, type);
  
          if (response?.status === 200) {
            let payloadData;
  
            switch (resultType) {
              case 'similarTickets':
                payloadData = response?.data?.similarTickets;
                break;
              case 'relevantArticles':
                payloadData = Array.isArray(response?.data?.relevantArticles) ? response?.data?.relevantArticles : [];
                dispatch(setButtonLabels({}));
                dispatch(setLoading({}));
                break;
              case 'suggestedMacros':
                payloadData = response?.data?.macros;
                break;
              case 'workflows':
                payloadData = response?.data?.data?.workflows;
                break;
              default:
                break;
            }
  
            dispatch({
              type: saveType,
              payload: payloadData
            });
          } else {
  
            dispatch(toggleSnackbar({
              message: errorMessage,
              status: true,
              severity: 'error'
            }));
          }
        } catch (error) {
          console.error(`Request failed for ${resultType}`, error);
          dispatch(toggleSnackbar({
            message: `Failed to get ${resultType}`,
            status: true,
            severity: 'error'
          }));
        }
  
        dispatch({
          type: loadingType,
          payload: false
        });
      }
    }
  };

  export const getAggregataForTicketResearch = (ticketDetails,agentDetails,userEmail,prismToken,orgId) => {
    return async (dispatch) =>{
      dispatch({
        type:RESEARCH_TAB_LOADING,
        payload:true
  
      })
      try{
        await Promise.all([
          dispatch(getDataForTicket(ticketDetails,"tickets",agentDetails,userEmail,prismToken,orgId,"similarTickets")),
          dispatch(getDataForTicket(ticketDetails,"articles",agentDetails,userEmail,prismToken,orgId,"relevantArticles",'title_search')),
          dispatch(getDataForTicket(ticketDetails,"macros",agentDetails,userEmail,prismToken,orgId,"suggestedMacros")),
          dispatch(getDataForTicket(ticketDetails,"workflows",agentDetails,userEmail,prismToken,orgId,"workflows"))
        ])
      }
      catch(error){
        console.error("aggregatedata call for ticket failed",error);
        dispatch(toggleSnackbar({
          message: "Failed to get aggregate data for ticket research",
          status: true,
          severity: "error",
        }))
      }
      
      dispatch({
        type:RESEARCH_TAB_LOADING,
        payload:false
  
      })
    }
  }