import axios from "axios";
import Amplify, { Auth }  from "aws-amplify";
import {
    API_ORG_INFO,
    GORGIAS_LAMBA_API,
    TRIGGER_DATA_IMPORT,
    DATA_IMPORT_STATUS,
    AGGREGATE_DATA,
    EVENTS,
}
from './constants/Endpoints'
import { toggleSnackbar,
  SAVE_ORG_DETAILS,
  ORG_DETAILS_LOADING,
  SAVE_DOMAIN,
  AGENT_DETAILS_LOADING,
  SAVE_AGENT_DETAILS,
  TICKET_DETAILS_LOADING,
  SAVE_TICKET_DETAILS,
  SAVE_TICKET_ID,
  SAVE_EMAIL,
  SAVE_ORG_ID,
  saveDomain,
  SAVE_PRISM_TOKEN,
  DATA_IMPORT_LOADING,
  SAVE_DATA_IMPORT_DETAILS,
  GPT_SANDBOX_LOADING,
  ADD_TO_GPT_SANDBOX,
  CUSTOMER_SUMMARY_LOADING,
  SAVE_CUSTOMER_SUMMARY,
  CASE_SUMMARY_LOADING,
  SAVE_CASE_SUMMARY,
  PAST_TICKETS_LOADING,
  SAVE_PAST_TICKETS,  
} from "../src/redux/actions/authActions";
import {clearGPTAnswersForResearch,clearResearch,
  GPT_ANSWER_ARTICLES,  startLoading,
  stopLoading,GPT_ANSWER_TICKETS_LOADING,GPT_ANSWER_ARTICLES_LOADING,GPT_ANSWER_MACROS_LOADING,
  GPT_ANSWER_MACROS,handleGPTResponse} from "../src/redux/actions/ticketActions"
import {gptOneTouchAnswersForTicketResearch,} from './apis/ticketResearch'
import { combinedActionMap } from "./constants/CombinedActionMap";

function findLinksInText(inputString) {// creates a map of links and replaces the links in the text with map keyys
  const regex = /(\[([^\]]*)\]\((https:\/\/[^\s)]+)\))|(https:\/\/[^\s)]+)/g;
  const linkObj = {};
  let countObj = {'value' : 0}
  const replacedString = inputString.replace(regex, (match, fullLink, content, url) => {
      countObj['value'] += 1;
      let count = countObj['value'];
      if (fullLink) {
          const linkString = `[${content}](link${count})`;
          const key = `link${count}`;
          linkObj[key] = `${url}`;
          return linkString;
      } else {
          
          const linkString = `[link${count}]`;
          const key = `[link${count}]`;
          linkObj[key] = match;
          return linkString;
      }
  });

  return [replacedString, linkObj];
}

export const getOrgData = (org) => {
    return async (dispatch,getState) => {
        dispatch({
          type :ORG_DETAILS_LOADING,
          payload : true
        })
        try {
          const response = await axios.get(`${API_ORG_INFO}`, {
            params: { subdomain: `${org}` },
            headers: {
              "x-api-key": process.env.REACT_APP_API_KEY
            }
          });
          const content = response?.data?.org_data;
          if (response.status === 200 && Object.keys(content).length > 0 ){
            Amplify.configure({
              Auth: {
                region: content["cognito"]["region"],
                userPoolId: content["cognito"]["userpool_id"],
                userPoolWebClientId: content["cognito"]["client_id"],
              },
            });
            dispatch({
              type: SAVE_ORG_ID,
              payload: content?._id
              })
          }
          if (response.status === 200 && Object.keys(response?.data).length > 0 && content?.dataImport){
            dispatch({
              type: SAVE_PRISM_TOKEN,
              payload: response?.data?.org_data?.dataImport?.accessToken
              })
          }

          dispatch({
            type: SAVE_ORG_DETAILS,
            payload: content ?? {}
          })
          dispatch({
            type: SAVE_DOMAIN,
            payload: org
          })
        } catch (ex) {
          console.error("error",ex);
          dispatch(toggleSnackbar({
            message: "Failed to get Org status",
            status: true,
            severity: "error",
          }))
        }
        dispatch({
          type :ORG_DETAILS_LOADING,
          payload : false
        })
      }
  };


export const getAgentDetails = (userEmail,ticketingSystem,orgId,prismToken)=>{
  return async (dispatch) =>{
    dispatch({
      type : AGENT_DETAILS_LOADING,
      payload : true
    })
    try {
      const response = await axios.post(`${GORGIAS_LAMBA_API}?orgid=${orgId}`,
                      {
                        operation: "verifyuser",
                        user_email: userEmail,
                        ticketingSystem: ticketingSystem,
                        orgId : orgId,
                      },
                        {
                          headers: {
                          "Authorization": `Bearer ${prismToken}`
                        }});
      if (response?.status === 200){
        if (response?.data?.verified){
          dispatch({
            type: SAVE_AGENT_DETAILS,
            payload: response?.data
          })
          dispatch({
            type: SAVE_EMAIL,
            payload: userEmail
          })
        }else{
          dispatch(toggleSnackbar({
            message: `${userEmail} is not a verified User`,
            status: true,
            severity: "error",
          }))

        }
        
    }else{
      dispatch(toggleSnackbar({
        message: "Failed to get agent status",
        status: true,
        severity: "error",
      }))
    }              
    } catch (ex) {
      console.error("error",ex);
      dispatch(toggleSnackbar({
        message: "Failed to get agent status",
        status: true,
        severity: "error",
      }))

  }
  dispatch({
    type :AGENT_DETAILS_LOADING,
    payload : false
  })
}
};


export const getTicketDetails = (ticketId,subdomain,ticketingSystem,orgId,prismToken)=>{
  return async(dispatch) =>{
    dispatch({
      type : TICKET_DETAILS_LOADING,
      payload : true
    })
    try {
      const response = await axios.post(`${GORGIAS_LAMBA_API}?orgid=${orgId}`,
                      {
                        subdomain: subdomain,
                        ticketId: ticketId,
                        operation : "getTicketDetails",
                        orgId : orgId,
                        ticketingSystem: ticketingSystem},
                        {
                          headers: {
                          "Authorization": `Bearer ${prismToken}`
                        }});
      if (response?.status === 200){
          dispatch({
            type: SAVE_TICKET_DETAILS,
            payload: response?.data
          })
          dispatch({
            type: SAVE_TICKET_ID,
            payload: ticketId
          })
          dispatch(clearResearch())
          dispatch(clearGPTAnswersForResearch())
      }else{
        dispatch(toggleSnackbar({
          message: response?.data,
          status: true,
          severity: "error",
        }))
      }              
    } catch (ex) {
      console.error("error",ex);
      dispatch(toggleSnackbar({
        message: "Failed to get ticket details",
        status: true,
        severity: "error",
      }))
  }
  dispatch({
    type :TICKET_DETAILS_LOADING,
    payload : false
  })

  }
};

export const initateDataImport = (payload,orgId,prismToken,subDomain)=>{
  return async(dispatch) =>{
    let dataImportTriggered = true;
    dispatch({
      type : DATA_IMPORT_LOADING,
      payload : true
    });
    try {
      const response = await axios.post(`${TRIGGER_DATA_IMPORT}?orgid=${orgId}`,
                      payload,
                      {
                        headers: {
                        "Authorization": `Bearer ${prismToken}`
                      }});
      if (response?.status === 200){
        dispatch(toggleSnackbar({
          message: `Data Import successfully triggered `,
          status: true,
          severity: "success",
        }))
        dispatch({
          type : DATA_IMPORT_LOADING,
          payload : true
        });
      }else{
        console.error(`data import status failed with response status code ${response?.status} : ${response?.data}`)
        dataImportTriggered = false;
        dispatch(toggleSnackbar({
          message: "Data Import triggered failed",
          status: true,
          severity: "error",
        }))
      }              
    } catch (ex) {
      console.error("error",ex);
      dataImportTriggered = true;
      dispatch(toggleSnackbar({
        message: "Data Import triggered failed",
        status: true,
        severity: "error",
      }))
  }
  if (dataImportTriggered) {
    const intervalId = setInterval(async () => {
      const dataImport = await dispatch(getDataImportStatus(orgId, prismToken, subDomain, intervalId));
      if (dataImport?.status === 'completed') {
        clearInterval(intervalId);
        dispatch({
          type: DATA_IMPORT_LOADING,
          payload: false
        });
        dispatch(updateWidget(orgId,prismToken,subDomain,payload?.ticketingSystem))
        dispatch(toggleSnackbar({
          message: "Data Import Completed",
          status: true,
          severity: "success",
        }))
      }
    }, 10000); // Check every 10 seconds
  } else {
    dispatch({
      type: DATA_IMPORT_LOADING,
      payload: false
    });
  }
  }
};


export const getDataImportStatus = (orgId, prismToken, subDomain, intervalId) => {
  return async (dispatch, getState) => {
    try {
      const response = await axios.get(`${DATA_IMPORT_STATUS}`,{
        params: { orgId: `${orgId}` },
        headers: {
          "Authorization": `Bearer ${prismToken}`
        }
      });
      if (response?.status === 200) {
        const state = getState().auth;
        if (state.orgId !== orgId) {
          dispatch({
            type: SAVE_ORG_ID,
            payload: orgId
            })
        }
        if (state.prismToken !== prismToken) {
          dispatch({
            type: SAVE_PRISM_TOKEN,
            payload: prismToken
            })
        }

        if (state.subDomain !== subDomain) {
          dispatch(saveDomain(subDomain));
          dispatch(getOrgData(subDomain))
        }
        dispatch({
          type: SAVE_DATA_IMPORT_DETAILS,
          payload: response?.data
        });
        return response?.data;
      } else {
        clearInterval(intervalId);
        dispatch({
          type: DATA_IMPORT_LOADING,
          payload: false
        });
        dispatch(toggleSnackbar({
          message: "Data Import status request failed",
          status: true,
          severity: "error",
        }));
        return 'failed';
      }
    } catch (error) {
      console.error("get dataimport status lambda failed error", error);
      clearInterval(intervalId);
      dispatch({
        type: DATA_IMPORT_LOADING,
        payload: false
      });
      dispatch(toggleSnackbar({
        message: "Data Import status request failed",
        status: true,
        severity: "error",
      }));
      return 'failed';
    }
  };
};


export const updateWidget = (orgId,prismToken,subDomain,ticketingSystem) =>{
  return async(dispatch) =>{
    try{
      const response = await axios.post(`${GORGIAS_LAMBA_API}?orgid=${orgId}`, 
        {'prismToken' : prismToken,'orgId' : orgId,'subDomain' : subDomain,'operation' : 'updatewidget',ticketingSystem: ticketingSystem},
        {
          headers: {
          "Authorization": `Bearer ${prismToken}`
        }
      }
      );
      if (response.status === 201){
        dispatch(toggleSnackbar({
          message: "Updated the ticket side bar widget!",
          status: true,
          severity: "success",
        }));
      }else{
        dispatch(toggleSnackbar({
          message: "Failed to update the widget in the ticket side bar",
          status: true,
          severity: "error",
        }));
      }
    }
    catch(error){
      console.error("Failed to update the widget in the ticket sidebar  due to ",error)
      dispatch(toggleSnackbar({
        message: "Failed to update the widget in the ticket side bar",
        status: true,
        severity: "error",
      }));
    }
  }
}



export const generateGPTAnswer = (actionType, query,ticketDetails,userEmail,userRole,intentsity,prismToken,orgId,selectedText,beforeText,afterText)=>{
  return async (dispatch) =>{
    dispatch({
      type: GPT_SANDBOX_LOADING,
      payload: true
    })
    try{
      const promptText=selectedText!==''?selectedText:query
      const  [queryText , anchorTagsobj] = findLinksInText(promptText);
      const question = queryText.replaceAll('<p>','\n').replaceAll('</p>','\n').replaceAll('<br>','\n')
      const requestBody = {
        prompt: actionType,
        text: question,
        ticketId: ticketDetails?.id,
        agent: userEmail,
        role: userRole,
        temperature: intentsity/10 *2
    };
    const response = await axios.post(`${AGGREGATE_DATA}?orgid=${orgId}`, 
          requestBody,
          {
            headers: {
            "Authorization": `Bearer ${prismToken}`
          }
        }
        );
    if (response?.status === 200){
      const responseData = response?.data?.response;
      let content = `${beforeText}${responseData}${afterText}`;
      for (const [key, link] of Object.entries(anchorTagsobj)) {
        const regex = new RegExp(`\\${key}`, 'g');
        content = content.replace(regex, link);
      }
      content = content.replaceAll('\n','<br/>');
      dispatch({
        type: ADD_TO_GPT_SANDBOX,
        payload: {
            action: actionType, content:content
        }
      })
      dispatch(updateEvent("gptTransform",`${actionType} : ${queryText}}`,ticketDetails?.id,ticketDetails?.subject,userEmail,orgId,prismToken));
    }else{
      dispatch(toggleSnackbar({
        message: `Request failed with ${response?.status}`,
        status: true,
        severity: "error",
      }));
    }
  }
  catch(error){
    console.error("error in generate GPT answer due to",error)
    dispatch(toggleSnackbar({
      message: `Failed to generate a response`,
      status: true,
      severity: "error",
    }));
  }
  dispatch({
    type: GPT_SANDBOX_LOADING,
    payload: false
  })
  }
} 
export const generateOneTouchAnswer = (actionType, subDomain, ticketingSystem, ticketDetails, userEmail, userId, buttonType, orgId, prismToken) => {
  return async (dispatch) => {
    startLoading(dispatch, GPT_SANDBOX_LOADING);
    const loadingActionType = `GPT_ANSWER_${buttonType.toUpperCase()}_LOADING`;
    startLoading(dispatch, loadingActionType);
    try {
      const operation = combinedActionMap?.[`${buttonType}`]?.['operation'];
      const event = combinedActionMap?.[`${buttonType}`]?.['event'];
      const response = await gptOneTouchAnswersForTicketResearch(operation,prismToken,subDomain,ticketingSystem,ticketDetails,orgId,userEmail,userId)
      if (response?.status === 200) {
        const content = response?.data.replaceAll('\n', '<br/>');
        dispatch({
          type: ADD_TO_GPT_SANDBOX,
          payload: {
            action: actionType,
            content: content
          }
        });
        handleGPTResponse(dispatch, buttonType, response?.data);

        dispatch(updateEvent(event, "", ticketDetails?.id, ticketDetails?.subject, userEmail, orgId, prismToken));
      } else {
        dispatch(toggleSnackbar({
          message: `One touch request failed`,
          status: true,
          severity: "error"
        }));
      }
    } catch (error) {
      console.error(`Error while generating one touch answer:`, error);
      dispatch(toggleSnackbar({
        message: `Failed to generate an answer for one touch`,
        status: true,
        severity: "error"
      }));
    }

    stopLoading(dispatch, loadingActionType);
    stopLoading(dispatch,GPT_SANDBOX_LOADING)
  };
};
  

export const insertTextToTicket = (comment,ticketingSystem,userEmail,orgId,ticketDetails,prismToken,) =>{
  return async (dispatch) =>{
   try{
    const requestBody = {
      ticketingSystem: ticketingSystem,
      operation: "addcomment",
      user_email: userEmail,
      ticketId: ticketDetails?.id,
      orgId: orgId,
      message : comment
    }
    const response = await axios.post(`${GORGIAS_LAMBA_API}?orgid=${orgId}`, 
            requestBody,
            {
              headers: {
              "Authorization": `Bearer ${prismToken}`
            }
          }
          );
    if (response?.status === 200){
      dispatch(toggleSnackbar({
        message: `Added the text to ticket`,
        status: true,
        severity: "success",
      }));
      dispatch(updateEvent("gptInsert",comment.replaceAll('<br>','\n').replaceAll('<p>','\n').replaceAll('</p>','\n'),ticketDetails?.id,ticketDetails?.subject,userEmail,orgId,prismToken))
    }else{
    console.error(`request failed with status code ${response?.status}`)
    dispatch(toggleSnackbar({
      message: `Request failed to add comment`,
      status: true,
      severity: "error",
    }));
   }
  }
   catch(error){
    console.error('Error in insertTextToTicket function due to',error)
    dispatch(toggleSnackbar({
      message: `Failed to add comment`,
      status: true,
      severity: "error",
    }));

   }
  }
}

export const getCustomerSummary = (subDomain,ticketingSystem,ticketDetails,userEmail,orgId,prismToken)=>{
  return async (dispatch) =>{
    dispatch({
      type:CUSTOMER_SUMMARY_LOADING,
      payload : true
    })
    try{
      const payload = {
        subdomain: subDomain,
          ticketingSystem: ticketingSystem,
          operation: "customersummary",
          subject: ticketDetails?.subject,
          ticketId: String(ticketDetails?.id),
          description: ticketDetails?.description,
          requester_id: String(ticketDetails?.requester?.id),
          orgId: orgId,
          fromApp : true
        }
      const response = await axios.post(`${GORGIAS_LAMBA_API}?orgid=${orgId}`, 
      payload,
            {
              headers: {
              "Authorization": `Bearer ${prismToken}`
            }
          }
          );
      if (response.status === 200){
        dispatch({
          type:SAVE_CUSTOMER_SUMMARY,
          payload : response?.data
        })
        dispatch(updateEvent('customerSummary',"",ticketDetails?.id,ticketDetails?.subject,userEmail,orgId,prismToken))
      }
      else{

        dispatch(toggleSnackbar({
          message: `Customer Summary request failed`,
          status: true,
          severity: "error",
        }));

      }
    }
    catch (error){
      console.error("error in customer summary api",error)
      dispatch(toggleSnackbar({
        message: `Failed to generate customer summary`,
        status: true,
        severity: "error",
      }));
    }
    dispatch({
      type:CUSTOMER_SUMMARY_LOADING,
      payload : false
    })
  }
}

export const getCaseSummary = (subDomain,ticketingSystem,ticketDetails,userEmail,orgId,prismToken)=>{
  return async (dispatch) =>{
    dispatch({
      type:CASE_SUMMARY_LOADING,
      payload : true
    })
    try{
      const payload = {
        subdomain: subDomain,
          ticketingSystem: ticketingSystem,
          operation: "caseSummary",
          subject: ticketDetails?.subject,
          ticketId: String(ticketDetails?.id),
          description: ticketDetails?.description,
          requester_id: String(ticketDetails?.requester?.id),
          orgId: orgId,
          fromApp : true
        }
      const response = await axios.post(`${GORGIAS_LAMBA_API}?orgid=${orgId}`, 
      payload,
            {
              headers: {
              "Authorization": `Bearer ${prismToken}`
            }
          }
          );
      if (response.status === 200){
        dispatch({
          type:SAVE_CASE_SUMMARY,
          payload : response?.data
        })
        dispatch(updateEvent('conversationSummary',"",ticketDetails?.id,ticketDetails?.subject,userEmail,orgId,prismToken));
      }else{

        dispatch(toggleSnackbar({
          message: `Case Summary request failed`,
          status: true,
          severity: "error",
        }));

      }
    }
    catch (error){
      console.error("error in customer summary api",error)
      dispatch(toggleSnackbar({
        message: `Failed to generate case summary`,
        status: true,
        severity: "error",
      }));
    }
    dispatch({
      type:CASE_SUMMARY_LOADING,
      payload : false
    })
  }
}


export const getCustomerPastTickets = (subDomain,ticketingSystem,ticketDetails,userEmail,orgId,prismToken)=>{
  return async (dispatch) =>{
    dispatch({
      type:PAST_TICKETS_LOADING,
      payload : true
    })
    try{
      const payload = {
        subdomain: subDomain,
          ticketingSystem: ticketingSystem,
          operation: "getpastickets",
          ticketId: String(ticketDetails?.id),
          requester_id: String(ticketDetails?.requester?.id),
          orgId: orgId,
          fromApp : true
        }
      const response = await axios.post(`${GORGIAS_LAMBA_API}?orgid=${orgId}`, 
      payload,
            {
              headers: {
              "Authorization": `Bearer ${prismToken}`
            }
          }
          );
      if (response.status === 200){
        dispatch({
          type:SAVE_PAST_TICKETS,
          payload : response?.data
        })
        dispatch(updateEvent('pastTickets',"",ticketDetails?.id,ticketDetails?.subject,userEmail,orgId,prismToken));
      }else{

        dispatch(toggleSnackbar({
          message: `Past Tickets request failed`,
          status: true,
          severity: "error",
        }));

      }
    }
    catch (error){
      console.error("error in customer summary api",error)
      dispatch(toggleSnackbar({
        message: `Failed to fetch the past tickets`,
        status: true,
        severity: "error",
      }));
    }
    dispatch({
      type:PAST_TICKETS_LOADING,
      payload : false
    })
  }
}


export const updateEvent = (type,resource,ticketId,queryText,userEmail,orgId,prismToken)=>{
  return async (dispatch) =>{
    try{
      const payload = {
        "event": {
            "type": type === 'like' || type === 'dislike' ? resource+type : type,
            "ticket_id": ticketId,
            "_queryText": queryText,
            "source": resource,
            "orgId": orgId,
            "agent": userEmail
        }
    }
      const response = await axios.post(`${EVENTS}?orgid=${orgId}`, 
      payload,
            {
              headers: {
              "Authorization": `Bearer ${prismToken}`
            }
          }
          );
      if (response.status !== 200){
        dispatch(toggleSnackbar({
          message: `Update event request failed`,
          status: true,
          severity: "error",
        }));

      }
    }
    catch (error){
      console.error("error in update events api",error)
      dispatch(toggleSnackbar({
        message: `Failed to update the events`,
        status: true,
        severity: "error",
      }));
    }
  }
}

