import './App.css';
import React,{useEffect,useState} from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import LandingPage  from './pages/landing/landing';
import Onboarding from './pages/onboarding/onboarding'
import ErrorSnackbar from './components/ErrorSnackbar/ErrorSnackbar';
import { useSelector } from "react-redux";
import { AgentAssist } from './pages/agentassist/agentassist';
import Dataimport from './pages/dataimport/dataimport';
import PageNotFound from './pages/pagenotfound/pagenotfound';
import { Research } from './pages/agentassist/research';
import {Summary} from './pages/agentassist/pageSummary';
import Login from './pages/login/login';
import { AuthProvider } from './utils/auth';
import ROUTES from './constants/routes';
import RequireAuth from './components/RequireAuth';
import ForgotPassword from './pages/ForgotPassword';
import { Auth } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { getOrgData } from './api';
import { saveAuthenticatedUser } from './redux/actions/authActions';
import { ArticleTransistion } from './components/ResearchComponent/ArticleTransistion';

function App() {
    const snackbarState = useSelector((state) => state.error);
    const navigate=useNavigate()
    const isAuthenticatedUser=useSelector((state)=>state.auth.isAuthenticatedUser)
    const [isAuthenticating, setIsAuthenticating] = useState(true);
    const orgDetails=useSelector((state)=>state.auth.orgDetails)
    const dispatch=useDispatch()

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const subDomain = queryParams.get('subDomain');
    
   useEffect(()=>{
      const authenticate=async()=>{
          try{
            await Auth.currentSession();
            const user = await Auth.currentAuthenticatedUser();
            dispatch(saveAuthenticatedUser(true))
           }catch(err) {
            console.error("err",err)
           }
          setIsAuthenticating(false)
      }
        if(!Object.keys(orgDetails).length && subDomain){
            dispatch(getOrgData(subDomain))
        }
        if(Object.keys(orgDetails).length){
            authenticate()
        }
    },[orgDetails?._id])
  return (
    <>
    <AuthProvider >
    <Routes>
    <Route path={ROUTES.ONBOARDING} element={<Onboarding />} />
    <Route exact path={ROUTES.DATAIMPORT}element={<Dataimport/>}/>
    
      { !isAuthenticating && (
          <>
            <Route exact path="/" element={<LandingPage />} />
            <Route path={ROUTES.ARTICLE} element={<ArticleTransistion />} />
            <Route 
              exact 
              path={ROUTES.AGENT.LOGIN} 
              element={!isAuthenticatedUser ? <Login /> : <PageNotFound />} 
            />
            <Route 
              exact 
              path={ROUTES.AGENT.FORGOT_PASSWORD} 
              element={!isAuthenticatedUser ? <ForgotPassword /> : <PageNotFound />} 
            />
            <Route path={ROUTES.SUMMARY} element={<RequireAuth><Summary /></RequireAuth>} />
            <Route path={ROUTES.GPT} element={<RequireAuth><AgentAssist /></RequireAuth>} />
            <Route path={ROUTES.RESEARCH} element={<RequireAuth><Research /></RequireAuth>} />
            <Route path={ROUTES.NOT_FOUND} element={<PageNotFound />} />
          </>
          )}
    </Routes>
    </AuthProvider>
    </>
  );
}

export default App;