const fastTrackApiLogicId = process.env.REACT_APP_ENDPOINT;
const AgentAssistApiLogicId = process.env.REACT_APP_AA_ENDPOINT;
const AgentAssistOILogicId = process.env.REACT_APP_AA_OI_ENDPOINT;
const ReactAppEnv =process.env.REACT_APP_ENV
const endPoints={
    DEV:{
        API_ORG_INFO:`https://${fastTrackApiLogicId}.execute-api.us-east-1.amazonaws.com/api/get-organisation-data`,
        GORGIAS_LAMBA_API:`https://${AgentAssistApiLogicId}.execute-api.us-east-1.amazonaws.com/api/gorgiasAA`,
        DATA_IMPORT_STATUS:`https://${AgentAssistOILogicId}.execute-api.us-east-1.amazonaws.com/v1/data-import-status`,
        TRIGGER_DATA_IMPORT:`https://${AgentAssistOILogicId}.execute-api.us-east-1.amazonaws.com/v1/data-import`,
        EVENTS:`https://${AgentAssistApiLogicId}.execute-api.us-east-1.amazonaws.com/api/events`,
        AGGREGATE_DATA:`https://${AgentAssistApiLogicId}.execute-api.us-east-1.amazonaws.com/api/aggregateData`,
        GEN_SUMMARY:`https://${AgentAssistApiLogicId}.execute-api.us-east-1.amazonaws.com/api/gpt`,
        SIMILAR_DATA:`https://${AgentAssistApiLogicId}.execute-api.us-east-1.amazonaws.com/api/similarData`,
        ORGANIZATION_BRANDING : `https://${AgentAssistApiLogicId}.execute-api.us-east-1.amazonaws.com/api/organization/branding`,

    },
    PROD:{
        API_ORG_INFO:`https://${fastTrackApiLogicId}.execute-api.us-east-1.amazonaws.com/api/get-organisation-data`,
        GORGIAS_LAMBA_API:`https://${AgentAssistApiLogicId}/gorgiasAA`,
        DATA_IMPORT_STATUS:`https://${AgentAssistOILogicId}/data-import-status`,
        TRIGGER_DATA_IMPORT:`https://${AgentAssistOILogicId}/data-import`,
        EVENTS:`https://${AgentAssistApiLogicId}/events`,
        AGGREGATE_DATA:`https://${AgentAssistApiLogicId}/aggregateData`,
        GEN_SUMMARY:`https://${AgentAssistApiLogicId}/gpt`,
        SIMILAR_DATA:`https://${AgentAssistApiLogicId}/similarData`,
        ORGANIZATION_BRANDING : `https://${AgentAssistApiLogicId}/organization/branding`,

    }
}
export const API_ORG_INFO = endPoints[ReactAppEnv]['API_ORG_INFO']
export const GORGIAS_LAMBA_API = endPoints[ReactAppEnv]['GORGIAS_LAMBA_API']
export const DATA_IMPORT_STATUS = endPoints[ReactAppEnv]['DATA_IMPORT_STATUS']
export const TRIGGER_DATA_IMPORT = endPoints[ReactAppEnv]['TRIGGER_DATA_IMPORT']
export const EVENTS =  endPoints[ReactAppEnv]['EVENTS']
export const AGGREGATE_DATA = endPoints[ReactAppEnv]['AGGREGATE_DATA']
export const GEN_SUMMARY =endPoints[ReactAppEnv]['GEN_SUMMARY']
export const SIMILAR_DATA = endPoints[ReactAppEnv]['SIMILAR_DATA']
export const ORGANIZATION_BRANDING = endPoints[ReactAppEnv]['RGANIZATION_BRANDING']
