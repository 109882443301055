import { toggleSnackbar,RESEARCH_TAB_LOADING } from "./authActions";
import {gptOneTouchAnswersForSearchResearch,getAggregateDataForSearch} from '../../apis/SearchResearch'
import {getWorkflows} from '../../apis/ticketResearch'
export const SEARCH_QUERY_WORKFLOWS = "SEARCH_QUERY_WORKFLOWS";
export const SEARCH_QUERY_WORKFLOWS_LOADING = "SEARCH_QUERY_WORKFLOWS_LOADING";
export const SEARCH_QUERY_GPT_ANSWER_TICKETS_LOADING = "SEARCH_QUERY_GPT_ANSWER_TICKETS_LOADING";
export const SEARCH_QUERY_GPT_ANSWER_ARTICLES_LOADING = "SEARCH_QUERY_GPT_ANSWER_ARTICLES_LOADING";
export const SEARCH_QUERY_GPT_ANSWER_MACROS_LOADING = "SEARCH_QUERY_GPT_ANSWER_MACROS_LOADING";
export const SEARCH_QUERY_GPT_ANSWER_TICKETS = "SEARCH_QUERY_GPT_ANSWER_TICKETS";
export const SEARCH_QUERY_GPT_ANSWER_ARTICLES = "SEARCH_QUERY_GPT_ANSWER_ARTICLES";
export const SEARCH_QUERY_GPT_ANSWER_MACROS = "SEARCH_QUERY_GPT_ANSWER_MACROS";
export const SEARCH_QUERY_SIMILAR_TICKETS_LOADING = "SEARCH_QUERY_SIMILAR_TICKETS_LOADING";
export const SEARCH_QUERY_SAVE_SIMILAR_TICKETS = "SEARCH_QUERY_SAVE_SIMILAR_TICKETS";
export const SEARCH_QUERY_RELEVANT_ARTICLES_LOADING = "SEARCH_QUERY_RELEVANT_ARTICLES_LOADING";
export const SEARCH_QUERY_SAVE_RELEVANT_ARTICLES = "SEARCH_QUERY_SAVE_RELEVANT_ARTICLES";
export const SEARCH_QUERY_SUGGESTED_MACROS_LOADING = "SEARCH_QUERY_SUGGESTED_MACROS_LOADING";
export const SEARCH_QUERY_SAVE_SUGGESTED_MACROS = "SEARCH_QUERY_SAVE_SUGGESTED_MACROS";
export const SET_SEARCH_QUERY_ARTICLES_BUTTON_LABELS = 'SET_SEARCH_QUERY_ARTICLES_BUTTON_LABELS';
export const SET_SEARCH_QUERY_ARTICLES_LOADING = 'SET_SEARCH_QUERY_ARTICLES_LOADING';
export const SET_SEARCH_ARTICLES_RESPONSES = "SET_SEARCH_ARTICLES_RESPONSES";
export const SAVE_SEARCH_QUERY = "SAVE_SEARCH_QUERY"

export const setSearchArticleResponse = (payload) => ({
    type: SET_SEARCH_ARTICLES_RESPONSES,
    payload
  });
  

  export const saveSearchQuery = (searchQuery)  => ({
    type: SAVE_SEARCH_QUERY,
    payload: searchQuery,
  })
  
  
  export const setSearchArticlesButtonLabels = (payload) => ({
    type: SET_SEARCH_QUERY_ARTICLES_BUTTON_LABELS,
    payload
  });
  export const setSearchArticlesLoading = (payload) => ({
    type: SET_SEARCH_QUERY_ARTICLES_LOADING,
    payload
  });


export const getGPTAnswerForSearch = (subDomain,ticketingSystem,searchQuery,ticketDetails,operation,agentDetails,userEmail,prismToken,orgId,answerType) => {
    const actionTypeLoading = `SEARCH_QUERY_GPT_ANSWER_${answerType.toUpperCase()}_LOADING`;
    const actionTypeSuccess = `SEARCH_QUERY_GPT_ANSWER_${answerType.toUpperCase()}`;
    const snackbarMessage = `Failed to get gpt answer for ${answerType.toLowerCase()}`;
  
    return async (dispatch) => {
        dispatch({
            type: actionTypeLoading,
            payload: true
        });
  
        try {
            const response = await gptOneTouchAnswersForSearchResearch(operation,prismToken,subDomain,ticketingSystem,ticketDetails,orgId,userEmail,agentDetails?.id,searchQuery);
            if (response?.status === 200) {
                let payload = response?.data;
                dispatch({
                    type: actionTypeSuccess,
                    payload: payload
                });
            } else {
                dispatch(toggleSnackbar({
                    message: snackbarMessage,
                    status: true,
                    severity: "error",
                }));
            }
        } catch (error) {
            dispatch(toggleSnackbar({
                message: snackbarMessage,
                status: true,
                severity: "error",
            }));
        }
  
        dispatch({
            type: actionTypeLoading,
            payload: false
        });
    };
  }

  export const clearGPTAnswersForSearch = () =>{

    return async (dispatch) =>{
      dispatch({
        type:SEARCH_QUERY_GPT_ANSWER_TICKETS,
        payload:""
  
      })
      dispatch({
        type:SEARCH_QUERY_GPT_ANSWER_ARTICLES,
        payload:""
  
      })
      dispatch({
        type:SEARCH_QUERY_GPT_ANSWER_MACROS,
        payload:""
  
      })
    }
  
  }

  
  export const getWorkFlowsForSearch = (ticketDetails,agentDetails,userEmail,prismToken,orgId,searchQuery) => {
    return async (dispatch) =>{
        dispatch({
          type:SEARCH_QUERY_WORKFLOWS_LOADING,
          payload:true
    
        })
        try{
          const response = await getWorkflows(agentDetails,prismToken,searchQuery,ticketDetails,orgId,userEmail);
          if (response?.status === 200){
            dispatch({
              type: SEARCH_QUERY_WORKFLOWS, 
              payload:response?.data?.data?.workflows
            })
          }
          else{
            dispatch(toggleSnackbar({
              message: `Failed to get workflows for ticket`,
              status: true,
              severity: "error",
            }));
          }
        }
        catch(error){
          console.error("failed to get the workflows",error)
          dispatch(toggleSnackbar({
            message: `Failed to get workflows for search`,
            status: true,
            severity: "error",
          }));
        }
        dispatch({
          type:SEARCH_QUERY_WORKFLOWS_LOADING,
          payload:false
    
        })    
    }
  }

  export const getAggregataForSearchQuery = (searchQuery, ticketDetails, agentDetails, userEmail, prismToken, orgId) => {
    return async (dispatch, getState) => {
      dispatch({
        type: RESEARCH_TAB_LOADING,
        payload: true
      });
  
      try {
        await Promise.all([
          dispatch(getSearchQueryData(searchQuery, ticketDetails, "tickets", agentDetails, userEmail, prismToken, orgId, "similarTickets")),
          dispatch(getSearchQueryData(searchQuery, ticketDetails, "articles", agentDetails, userEmail, prismToken, orgId, "relevantArticles", 'title_search')),
          dispatch(getSearchQueryData(searchQuery, ticketDetails, "macros", agentDetails, userEmail, prismToken, orgId, "suggestedMacros")),
          dispatch(getWorkFlowsForSearch(ticketDetails, agentDetails, userEmail, prismToken, orgId, searchQuery))
        ]);
        dispatch(saveSearchQuery(searchQuery));
        dispatch(clearGPTAnswersForSearch());
      } catch (error) {
        console.error("aggregate data call for search failed", error);
        dispatch(toggleSnackbar({
          message: "Failed to get aggregate data for search",
          status: true,
          severity: "error"
        }));
      }
  
      dispatch({
        type: RESEARCH_TAB_LOADING,
        payload: false
      });
    };
  };

  const getSearchQueryData = (searchQuery, ticketDetails, operation, agentDetails, userEmail, prismToken, orgId, resultType, type = undefined) => {
    return async (dispatch) => {
      let loadingType, saveType,errorMessage;
  
      switch (resultType) {
        case 'similarTickets':
          loadingType = SEARCH_QUERY_SIMILAR_TICKETS_LOADING;
          saveType = SEARCH_QUERY_SAVE_SIMILAR_TICKETS;
          errorMessage = 'Failed to get similar tickets';
          break;
        case 'relevantArticles':
          loadingType = SEARCH_QUERY_RELEVANT_ARTICLES_LOADING;
          saveType = SEARCH_QUERY_SAVE_RELEVANT_ARTICLES;
          errorMessage = 'Failed to get relevant articles';
          break;
        case 'suggestedMacros':
          loadingType = SEARCH_QUERY_SUGGESTED_MACROS_LOADING;
          saveType = SEARCH_QUERY_SAVE_SUGGESTED_MACROS;
          errorMessage = 'Failed to get suggested macros';
          break;
        default:
          throw new Error('Invalid result type');
      }
  
      dispatch({
        type: loadingType,
        payload: true
      });
  
      try {
        const response = await getAggregateDataForSearch(ticketDetails, searchQuery, operation, agentDetails, userEmail, prismToken, orgId, type);
        if (response?.status === 200) {
          let payloadData;
  
          switch (resultType) {
            case 'similarTickets':
              payloadData = response?.data?.similarTickets;
              break;
            case 'relevantArticles':
              payloadData = Array.isArray(response?.data?.relevantArticles) ? response?.data?.relevantArticles : [];
              dispatch(setSearchArticlesButtonLabels({}));
              dispatch(setSearchArticlesLoading({}));
              break;
            case 'suggestedMacros':
              payloadData = response?.data?.macros;
              break;
            default:
              break;
          }
  
          dispatch({
            type: saveType,
            payload: payloadData
          });
        } else {
          dispatch(toggleSnackbar({
            message: errorMessage,
            status: true,
            severity: 'error'
          }));
        }
      } catch (error) {
        console.error(`Request failed for ${resultType}`, error);
        dispatch(toggleSnackbar({
          message: `Failed to get ${resultType}`,
          status: true,
          severity: 'error'
        }));
      }
  
      dispatch({
        type: loadingType,
        payload: false
      });
    };
  };