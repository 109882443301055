import React, {  useCallback, useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import './GPTSandbox.css'; // Assuming you have a CSS file for additional styles
import { FaRedo } from "react-icons/fa";
import { FaUndo } from "react-icons/fa";
import Tooltip from '@mui/material/Tooltip'
import { IoCaretBackOutline } from "react-icons/io5";
import { IoCaretForwardSharp } from "react-icons/io5";
import {generateGPTAnswer, generateOneTouchAnswer, insertTextToTicket, updateEvent} from '../../api'
import { useDispatch, useSelector } from 'react-redux';
import { ADD_TO_GPT_SANDBOX, GPT_REDO, GPT_UNDO, UPDATE_GPT_SANDBOX_CONTENT} from '../../redux/actions/authActions';
import CircularIndeterminate from '../../components/circularspinner/circularspinner';
import SplitButtonGroup from '../../components/SplitButton';
import ReactQuillEditor from '../../components/ReactQuillEditor';
import {combinedActionMap} from '../../constants/CombinedActionMap';


const GptSandbox = ({isTicketIdValid,agentDetails, userEmail }) => {
    const orgDetails =  useSelector((state)=>state.auth.orgDetails);
    const prismToken = useSelector((state)=>state.auth.prismToken);
    const ticketDetails = useSelector((state)=>state.ticket.ticketDetails);
    const ticketingSystem = orgDetails?.ticketing?.platform;
    const sandBoxStack=useSelector((state)=>state.sandbox.sandBoxStack)
    const sandBoxLoading=useSelector((state)=>state.sandbox.gptSandboxLoading)
    const gptAnswerForTickets = useSelector((state)=>state.ticket.gptAnswerForTickets);
    const gptAnswerForArticles = useSelector((state)=>state.ticket.gptAnswerForArticles);
    const gptAnswerForMacros = useSelector((state)=>state.ticket.gptAnswerForMacros);
    const currentSandBoxIndex=useSelector((state)=>state.sandbox.sandBoxStack.gptSandBoxCurrentContentIndex)
    const [intensity,setIntensity] = useState(3);
    const [undoTitle,setUndoTitle]=useState('undo')
    const [redoTitle,setRedoTitle]=useState('redo')
    const [temperatureToolTip,setTemperatureTop] = useState("Balance")
    const [sandBoxContent,setSandboxContent] = useState(sandBoxStack.gptSandBox[currentSandBoxIndex].content);
    const textRef = useRef(null);
    const [selectedText, setSelectedText] = useState('');
    const [beforeText, setBeforeText] = useState('');
    const [afterText, setAfterText] = useState('');
    const dispatch = useDispatch();

    const handleMouseUp = useCallback(() => {
            const editor = textRef?.current?.getEditor();
            const range = editor.getSelection();
            if (range && range.length !== 0) {
                const fullText = editor.getText();
                const selectedText = fullText.substring(range.index, range.index + range.length);
                const beforeText = fullText.substring(0, range.index);
                const afterText = fullText.substring(range.index + range.length);

                setSelectedText(selectedText);
                setBeforeText(beforeText);
                setAfterText(afterText);
            } else {
                clearSelection();
            }
        },[textRef.current]);

    useEffect(() => {
        const editor = textRef?.current?.getEditor();
        editor?.root?.addEventListener('mouseup', handleMouseUp);

        return () => {
            editor?.root?.removeEventListener('mouseup', handleMouseUp);
        };
    }, [textRef.current,handleMouseUp]);

    const clearSelection = () => {
        setSelectedText('');
        setBeforeText('');
        setAfterText('');
    };


    const handleButtonClick = (buttonType) => {     
        const buttonAnswerMap = {
            "Articles" : gptAnswerForArticles,
            "Tickets" : gptAnswerForTickets,
            "Macros" : gptAnswerForMacros
        }
        if (buttonAnswerMap[buttonType].length){
            dispatch({
                type: ADD_TO_GPT_SANDBOX,
                payload: {
                   action: `GPT ${buttonType}`, content: buttonAnswerMap[buttonType].replaceAll('\n','<br>')
                }
              })
        }
        else{
            dispatch(generateOneTouchAnswer(`GPT ${buttonType}`,orgDetails?.subdomain_name ,ticketingSystem,ticketDetails,userEmail,agentDetails?.id,buttonType,orgDetails?._id,prismToken));
        }

        
    };

    const updateSandboxContent=(value)=> { 
        setSandboxContent(value);

        dispatch({
            type: UPDATE_GPT_SANDBOX_CONTENT,
            payload: { index: currentSandBoxIndex, content:value }
        });  
    }

    function adjustIntensity(increment) {
        var slider = document.getElementById('mySlider');
        const newValue = parseInt(slider.value) + increment;
        if (newValue >= slider.min && newValue <= slider.max) {
            slider.value = newValue;
            setIntensity(slider.value);
            updateCounter(slider.value);
        }
    }

    function updateCounter(value) {
        switch (parseInt(value)) {
            case 1:
                setTemperatureTop("Mild")
                break;
            case 2:
                setTemperatureTop("Moderate")
                break;
            case 3:
                setTemperatureTop("Balance")
                break;
            case 4:
                setTemperatureTop("High")
                break;
            case 5:
                setTemperatureTop("Maximum")
                break;
            default:
                setTemperatureTop("")
                
        }
    }

    function renderCurrentSandboxState() {
      const  currentContentIndex = currentSandBoxIndex
        const updateButtonState = (buttonId, index, stack, isUndo) => {
               if(isUndo){
                        const title=currentContentIndex<=0? 'Undo':`Undo ${sandBoxStack['gptSandBox'][currentContentIndex]['action']}`
                        setUndoTitle(title)
               } 
               else{
                        const title=currentContentIndex<sandBoxStack['gptSandBox'].length-1?`Redo ${sandBoxStack['gptSandBox'][currentContentIndex+1]['action']}`:'Redo'
                        setRedoTitle(title)
               }
        };
        
        updateButtonState("undoButton", sandBoxStack.gptSandBoxCurrentContentIndex, sandBoxStack.gptSandBox, true);
        updateButtonState("redoButton", sandBoxStack.gptSandBoxCurrentContentIndex, sandBoxStack.gptSandBox, false);
        // updateButtonState("macroUndoButton", sandBoxStack.macroCurrentContentIndex, sandboxStack.macro, true);
        // updateButtonState("macroRedoButton", sandBoxStack.macroCurrentContentIndex, sandboxStack.macro, false);
    } 


    function undoSandboxOperation() {
       const  currentContentIndex = sandBoxStack[`gptSandBoxCurrentContentIndex`]
        if (currentContentIndex > 0) {
            dispatch({type:GPT_UNDO})
        }
    }

    function redoSandboxOperation() {
       const currentContentIndex = sandBoxStack[`gptSandBoxCurrentContentIndex`]
        if (currentContentIndex < sandBoxStack['gptSandBox'].length - 1) {
            dispatch({
                type:GPT_REDO
            })
        }
    }

    const handleGPTGenerate=(actionType)=>{
        if (sandBoxContent?.length > 0) {
           dispatch(generateGPTAnswer(actionType, sandBoxContent.replaceAll("<br>","\n"),ticketDetails,userEmail,agentDetails?.role,intensity,prismToken,orgDetails?._id,selectedText,beforeText,afterText));
        }
    }

    const handleClearContent=(actionType)=>{
        if(sandBoxContent?.length){

        dispatch({
            type: ADD_TO_GPT_SANDBOX,
            payload: {
             action: actionType, content: ''
            }
          })    
        }      
        dispatch(updateEvent("gptClear","",ticketDetails?.id,ticketDetails?.subject,userEmail,orgDetails?._id,prismToken))
    }

    const handleComment=()=>{
        const content = selectedText || sandBoxContent;
        dispatch(insertTextToTicket(content,ticketingSystem,userEmail,orgDetails?._id,ticketDetails,prismToken))
    }

    useEffect(()=>{
        setSandboxContent(sandBoxStack['gptSandBox'][currentSandBoxIndex]['content'])
        renderCurrentSandboxState();
        clearSelection()
    },[sandBoxStack['gptSandBox'],currentSandBoxIndex])


    return (
        <>{sandBoxLoading?<CircularIndeterminate/>:
        <>
            {isTicketIdValid ? 
            <div className="sandBoxClass">
                <div className="dropdownContainer">
                    <SplitButtonGroup options={["Articles","Tickets","Macros"]} onClickSubmit={handleButtonClick}/>
                    <div style={{display:"flex",justifyContent:"space-around",width:"15%"}}>
                        <Tooltip title={undoTitle} id='undoButton' style={{cursor:'pointer'}} >
                            <span  onClick={undoSandboxOperation}>
                            <FaUndo />
                            </span>
                        </Tooltip>
                        <Tooltip  title={redoTitle} id='redoButton' style={{cursor:'pointer'}}>
                            <span  onClick={redoSandboxOperation}>
                            <FaRedo/>
                            </span>
                        </Tooltip>
                    </div>
                </div>
                <div style={{width:"100%",height:"80%",border:"1px solid",borderRadius:"10px 10px",display:"flex",flexDirection:"row",flexWrap:"wrap"}}>
                    <ReactQuillEditor content={sandBoxContent} onChange={updateSandboxContent} ref={textRef}/> 
                    <div style={SandboxOptionsContainerStyle}>
                        <div>
                            <div id="temperature-bar">
                                
                                <Tooltip title="Decrease">
                                <span>
                                    <IoCaretBackOutline onClick={()=>adjustIntensity(-1)}/>
                                    </span>
                                </Tooltip>
                                
                            <Tooltip title="Set your GPT intensity before modifying" placement="top-start">
                            <input type="range" min="1" max="5" value={String(intensity)} step="1" class="slider" id="mySlider" oninput="updateCounter('mySlider')" style={{height:"10px"}}></input>

                            </Tooltip>
                            
                                <Tooltip title="Increase">
                                <span>
                                    <IoCaretForwardSharp onClick={()=>adjustIntensity(1)}/>
                                </span>
                            </Tooltip>
                            </div>
                            <div class="temperature-value" id="temperature-value-container" > 
                                                    <span style={{fontWeight:"600"}}> Intensity : </span>    <span class="temperature-tooltip" id="temperature-tooltip">{temperatureToolTip}</span>
                            </div>

                        </div>
                        
                        <div style={{display:"flex",flexDirection:"row",width:"40%",justifyContent:"space-around"}} id="gpt-prompts">
                        <span onClick={() => handleGPTGenerate('Make this succinct')}>
                                <Tooltip title="Make this succinct"  placement="bottom" >
                                    🤏
                                </Tooltip>
                            </span>
                            <span onClick={() => handleGPTGenerate('Clean this up')}>
                                <Tooltip title="Make this professional"   placement="bottom" >
                                    🧹
                                </Tooltip>
                            </span>
                            <span onClick={() => handleGPTGenerate('Make this more empathetic')}>
                                <Tooltip title="Add empathy"   placement="bottom">
                                    📝
                                </Tooltip>
                            </span>
                            <span onClick={() => handleGPTGenerate('Make this friendlier')}>
                                <Tooltip title="Make this friendlier"   placement="bottom" >
                                    🧑
                                </Tooltip>
                            </span>

                            
                            
                        </div>
                                
                        

                    </div>
                    <div style={{width:"100%",display:"flex",justifyContent:"flex-end",borderTop:"2px solid #D0D4D7",padding: "1rem"}}>
                        <Tooltip title="Clear Sandbox Content"   placement="bottom" >
                            <Button onClick={()=>handleClearContent('clear')} variant="contained" style={{height:"2rem",marginRight:"2rem",background:"#152938"}}>clear</Button>
                        </Tooltip>
                        <Tooltip title="Insert the sandbox content"   placement="bottom" >
                            <Button onClick={()=>handleComment('comment')} variant="contained" style={{height:"2rem",marginRight:"2rem",background:"#152938"}} >Insert</Button>
                        </Tooltip>
                    </div>
                </div>

                
            </div> : <div></div>}
            </>}
        </>
    );
};

const SandboxOptionsContainerStyle = {
    width:"100%",
    height:"15%",
    padding: "1rem",
    display:"flex",
    marginTop:'1rem',
    marginBottom:'0.5rem',
    justifyContent:'space-between',
}

export default GptSandbox;
