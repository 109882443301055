// reducers/authReducer.js

import { 
  SAVE_EMAIL,
  AGENT_DETAILS_LOADING,
  SAVE_AGENT_DETAILS, 
  SAVE_DOMAIN,
  SAVE_ORG_DETAILS,
  ORG_DETAILS_LOADING,
  SAVE_ORG_ID,
  SAVE_PRISM_TOKEN,
  DATA_IMPORT_LOADING,
  SAVE_DATA_IMPORT_DETAILS,
  SAVE_AUTHENTICATED_USER
  

} from '../actions/authActions';

const initialState = {
  domain: '',
  email: '',
  orgDetails : {},
  orgId :'',
  prismToken : '',
  orgDetailsLoading: false,
  agentDetails : {},
  agentDetailsLoading: false,
  dataImportLoading : false,
  dataImportDetails : {},
  isAuthenticatedUser:false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_EMAIL:
      return {
        ...state,
        email: action.payload
      };
    case SAVE_DOMAIN:
      return {
        ...state,
        domain: action.payload,
      };
    case SAVE_ORG_DETAILS:
        return {
          ...state,
          orgDetails: action.payload,
        };
    case SAVE_ORG_ID:
      return{
        ...state,
        orgId: action.payload
      }
    case SAVE_PRISM_TOKEN:
      return{
        ...state,
        prismToken : action.payload
      }
    case ORG_DETAILS_LOADING:
      return {
        ...state,
        orgDetailsLoading: action.payload,
      };
    case SAVE_AGENT_DETAILS:
      return {
        ...state,
        agentDetails: action.payload,
      };
    case AGENT_DETAILS_LOADING:
      return {
        ...state,
        agentDetailsLoading: action.payload,
      };
    case DATA_IMPORT_LOADING:
      return {
        ...state,
        dataImportLoading : action.payload
      }
    case SAVE_DATA_IMPORT_DETAILS:
      return {
        ...state,
        dataImportDetails : action.payload
      }
    case SAVE_AUTHENTICATED_USER:
      return {
        ...state,
        isAuthenticatedUser : true
      }  
    default:
      return state;
  }
};

export default authReducer;
