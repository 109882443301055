import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CircularIndeterminate from "../circularspinner/circularspinner";
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { Search } from "./Search";
import { TicketResearch } from "./TicketResearch";
import { InputField,InputArea,Input } from "../../pages/login/style";
import { FaSearch } from "react-icons/fa";
import { IoSend } from "react-icons/io5";
import { useDispatch } from 'react-redux';
import {toggleSnackbar ,saveResearchTab} from '../../redux/actions/authActions'
import { TicketComponent } from '../TicketComponent/TicketComponent';
import { useSelector } from 'react-redux';

const restChildrenStyles = {
    position: 'relative',
    top: "6rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "80vh",
    justifyContent:"space-between"
  }



  const CustomTabs = styled(Tabs)({
    '& .MuiTabs-indicator': {
      display: 'none',
    }
  });
  
  const CustomTab = styled(Tab)(({ theme }) => ({
    textTransform: 'none',
    fontSize: theme.typography.pxToRem(15),
    marginTop: theme.spacing(1),
    color: '#152938',
    fontWeight:"600",
    marginRight:"1rem",
    backgroundColor: 'white',
    borderRadius:"5px",
    '&.Mui-selected': {
      color: 'white',
      backgroundColor: '#152938',
    },
    '&:hover': {
      color: 'white',
      backgroundColor: '#152938',
    }
    
  }));

export const ResearchComponent= ({subDomain,ticketingSystem,prismToken, ticketId,orgId,agentDetails,userEmail }) => {

    const options = ["Search Research", "Ticket Research"];
    // const [value, setValue] = React.useState(0);
    const value = useSelector((state)=>state.ticket.researchCurrentTab)
    const currentSearchQuery = useSelector((state)=>state.search.searchQuery);
    const [searchQuery,setSearchQuery] = React.useState(currentSearchQuery);
    const [validSearch,setValidSearch] = React.useState(false);
    const dispatch = useDispatch();
    
    const ticketDetails = useSelector((state)=>state.ticket.ticketDetails);


    const handleChange = (event, newValue) => {
      dispatch(saveResearchTab(newValue));
      };


      const renderContent = () => {
        switch (value) {
          case 0:
            return <Search searchQuery = {searchQuery} subDomain={subDomain} ticketingSystem={ticketingSystem} prismToken={prismToken} ticketId={ticketId} orgId={orgId} agentDetails = {agentDetails} userEmail = {userEmail} ticketDetails={ticketDetails} validSearch = {validSearch}/>;
          case 1:
            return <TicketResearch subDomain={subDomain} ticketingSystem={ticketingSystem} prismToken={prismToken} ticketId={ticketId} orgId={orgId}  agentDetails = {agentDetails} userEmail = {userEmail} ticketDetails={ticketDetails}/>;
          default:
            return null;
        }
      };

    const handleSearchOnClick = () =>{
      if (searchQuery.length <= 3){
        dispatch(toggleSnackbar({
          message: "The search query should be more than 3 characters long.",
          status: true,
          severity: "warning",
        }))
        return
      }
      else{
        dispatch(saveResearchTab(0));
        setValidSearch(true)

      }

    }

    return (
        <>
        <div style={restChildrenStyles}>

          <TicketComponent subDomain={subDomain} ticketingSystem={ticketingSystem} prismToken={prismToken} ticketId={ticketId} orgId={orgId}/>
            <div style={{height:"80%",border:"2px solid black",borderRadius:"0.5rem",padding:"1rem",width:"40%"}}>

                <Box sx={{ width: '100%', bgcolor: 'background.paper' }} style={{justifyContent:"space-between"}}>

                    <CustomTabs value={value} onChange={handleChange} centered>
                    {options.map((item, index) => (
                     
                        <CustomTab id={item.split(" ").join('')} key={index} label={item} />
                    ))}
                    </CustomTabs>


                </Box>
                
                <div style={{height:"88%",display:"flex",flexDirection:"column",justifyContent:"space-between"}}>

                    <div style={{height:"85%",border:"1px solid black",borderRadius:"0.5rem",padding:"1rem",marginTop:"1rem",overflowY:"scroll"}}>
                        {renderContent()}
                
                    </div>
                    <div style={{height:"10%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-around"}}>
                        <FaSearch style={{fontSize:"inherit"}}/>
                        
                        <InputField style={{fontSize:"1rem",width:"60%",height:"100%",marginBottom:"0"}}> 
                            
                                <InputArea style={{fontSize:"1rem",width:"100%",border:"1px solid #152938",height:"inherit"}}>
                                    <Input style={{width:"inherit"}} id="search-input"
                                    type="text"
                                    placeholder="Search Knowledge Base"
                                    value={searchQuery}
                                    onChange={(e) => {
                                      setSearchQuery(e.target.value)
                                       setValidSearch(false)

                                    }
                                      
                                      }
                                    />
                                </InputArea>
                        </InputField>
                        <div style={{height:"100%",width:"4rem",display:"flex",borderRadius:"5px 5px",justifyContent:"center",alignItems:"center"}} onClick={handleSearchOnClick} id="searchbutton">
                            <IoSend style={{fontSize:"2rem",padding:"0.5rem",cursor:"pointer"}}/>
                        </div>
                        
                    </div>
                </div>

                </div>

        </div>
       
        </>
    )
}