import {
    SET_BUTTON_LABELS, SET_LOADING,SET_ARTICLE_RESPONSE
} from '../actions/ticketActions'
import {
    SET_SEARCH_QUERY_ARTICLES_BUTTON_LABELS,
    SET_SEARCH_QUERY_ARTICLES_LOADING,SET_SEARCH_ARTICLES_RESPONSES
} from '../actions/searchActions'
const initialState = {
    buttonLabels: {},
    loading: {},
    searchArticlesbuttonlables: {},
    searchArticlesloading: {},
    articleResponses : {},
    searchArticleResponses : {}
};

export const articleReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_BUTTON_LABELS:
            return {
                ...state,
                buttonLabels: Object.keys(action.payload).length === 0 ? {} : {
                    ...state.buttonLabels,
                    ...action.payload
                }
            };
        case SET_LOADING:
            return {
                ...state,
                loading: Object.keys(action.payload).length === 0 ? {} : {
                    ...state.loading,
                    ...action.payload
                }
            };
        case SET_SEARCH_QUERY_ARTICLES_BUTTON_LABELS:
            return {
                ...state,
                searchArticlesbuttonlables: Object.keys(action.payload).length === 0 ? {} : {
                    ...state.searchArticlesbuttonlables, // Correct reference
                    ...action.payload
                }
            };
        case SET_SEARCH_QUERY_ARTICLES_LOADING:
            return {
                ...state,
                searchArticlesloading: Object.keys(action.payload).length === 0 ? {} : {
                    ...state.searchArticlesloading, // Correct reference
                    ...action.payload
                }
            };
        case SET_ARTICLE_RESPONSE:
            return {
                ...state,
                articleResponses: {
                ...state.articleResponses,
                [action.payload.aid]: {
                    ...state.articleResponses[action.payload.aid],
                    [action.payload.type]: action.payload.response
                }
                }
            };
        case SET_SEARCH_ARTICLES_RESPONSES:
            return {
                ...state,
                searchArticleResponses: {
                ...state.searchArticleResponses,
                [action.payload.aid]: {
                    ...state.searchArticleResponses[action.payload.aid],
                    [action.payload.type]: action.payload.response
                }
                }
            };
        default:
            return state;
    }
};
