import { SimilarTickets } from "./SimilarTickets"
import {RelevantArticles} from './RelevantArticles'
import {Workflows} from './Workflows'
import { useEffect } from "react"
import {useDispatch, useSelector} from 'react-redux';
import {getAggregataForTicketResearch} from '../../redux/actions/ticketActions'
import CircularIndeterminate from "../circularspinner/circularspinner";

export const TicketResearch = ({subDomain,ticketingSystem,prismToken, ticketId,orgId,agentDetails,userEmail,ticketDetails})=>{
    const dispatch = useDispatch();

    const similarTickets = useSelector((state)=>state.ticket.similarTickets);
    const relevantArticles = useSelector((state)=>state.ticket.relevantArticles);
    const isRelevantArticlesLoading = useSelector ((state)=>state.ticket.articlesloading)
    const isloading = useSelector((state)=>state.ticket.researchTabloading);
    const buttonLabels = useSelector((state) => state.article.buttonLabels);
    const loading = useSelector((state) => state.article.loading);
    const articleResponses = useSelector(state => state.article.articleResponses);
    const workflowsLoading = useSelector((state)=>state.ticket.workflowsLoading)
    const workflows = useSelector((state)=>state.ticket.workflows)
    useEffect(()=>{
        
        dispatch(getAggregataForTicketResearch(ticketDetails,agentDetails,userEmail,prismToken,orgId))
        
    },[ticketId,orgId])
    return <>
    { isloading ? <CircularIndeterminate height={"10vh"}/> :
            <div >
                <Workflows workflows = {workflows} isloading = {workflowsLoading} userEmail ={userEmail} ticketDetails = {ticketDetails} orgId = {orgId} prismToken = {prismToken} renderType = {"ticket"} subDomain={subDomain} ticketingSystem = {ticketingSystem} agentDetails = {agentDetails} queryText={ticketDetails?.latest_question_from_customer ?? ticketDetails?.subject}/>
                <RelevantArticles relevantArticles={relevantArticles} articleResponses={articleResponses} isLoading={isRelevantArticlesLoading} buttonLabels={buttonLabels} loading={loading} renderType={"ticket"} prismToken = {prismToken} orgId={orgId} queryText={ticketDetails?.latest_question_from_customer ?? ticketDetails?.subject} ticketDetails={ticketDetails} userEmail={userEmail} ticketingSystem={ticketingSystem} subDomain={subDomain} agentDetails={agentDetails}/>
                <SimilarTickets similarTickets={similarTickets} userEmail= {userEmail} ticketDetails = {ticketDetails} orgId={orgId} prismToken={prismToken} renderType={"ticket"} agentDetails = {agentDetails} ticketingSystem={ticketingSystem} subDomain={subDomain} queryText={ticketDetails?.latest_question_from_customer ?? ticketDetails?.subject}/>
            </div>
    }
    </>
}