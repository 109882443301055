import React from 'react';
import { Modal, Button } from '@mui/material';

const ModalComponent = ({ open, handleClose, handleProceed, title, description }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div style={{ padding: '20px', backgroundColor: 'white', borderRadius: '8px', margin: 'auto', width: '50%', textAlign: 'center' }}>
        <h2 id="modal-modal-title">{title}</h2>
        <p id="modal-modal-description">{description}</p>
        <Button onClick={handleProceed}>Proceed</Button>
        <Button onClick={handleClose}>Cancel</Button>
      </div>
    </Modal>
  );
};

export default ModalComponent;
