import axios from "axios";
import { AGGREGATE_DATA,GORGIAS_LAMBA_API,SIMILAR_DATA } from "../constants/Endpoints";

export const getAggregateDataForTicket = async (ticketDetails,operation,agentDetails,userEmail,prismToken,orgId,type=undefined,body={})=>{
    const payload = {

        ...body,
        question: ticketDetails?.latest_question_from_customer ?? ticketDetails?.subject,
        operation: operation,
        ticketId: ticketDetails?.id,
        ticketTags: ticketDetails?.tags,
        agentId: agentDetails?.id,
        groupIds: [],
        agentUserIds: [],
        agent: userEmail,
        role: agentDetails?.role,
        subject: ticketDetails?.subject ??  "",
        description: ticketDetails?.description ?? "",
        conversations: ticketDetails?.conversations ?? []
        }
        if (type){
            payload['type'] = type
        }

        try{
            const response = await axios.post(`${AGGREGATE_DATA}?orgid=${orgId}`, 
              payload,
              {
                headers: {
                "Authorization": `Bearer ${prismToken}`
              }
            }
            );
            return response
          }
          catch(error){
            console.error("error in making aggregate data call",error)
            throw error;
          }
    }

export const gptOneTouchAnswersForTicketResearch = async (operation,prismToken,subDomain,ticketingSystem,ticketDetails,orgId,userEmail,userId) =>{
      const requestBody = {
        isUserEmailAvailable: true,
        subdomain: subDomain,
        ticketingSystem: ticketingSystem,
        operation: operation,
        user_email: userEmail,
        subject: ticketDetails?.subject,
        ticketId: ticketDetails?.id,
        user_id: userId,
        customer_id: ticketDetails?.customer?.id ?? "",
        orgId: orgId,
        fromApp :true,
      }

      try{
        const response = await axios.post(`${GORGIAS_LAMBA_API}?orgid=${orgId}`, 
          requestBody,
          {
            headers: {
            "Authorization": `Bearer ${prismToken}`
          }
        }
        );
        return response
      }
      catch(error){
        console.error("error in making gpt one touch call for ticket research",error)
        throw error;
      }
}

export const getWorkflows = async (agentDetails,prismToken,question,ticketDetails,orgId,userEmail) =>{
  const requestBody = {
    question : question,
    dataType: "workflows",
    dataLength: 3,
    ticketId: ticketDetails?.id,
    agent: userEmail,
    role: agentDetails?.role 
}
    try{
    const response = await axios.post(`${SIMILAR_DATA}?orgid=${orgId}`, 
      requestBody,
      {
        headers: {
        "Authorization": `Bearer ${prismToken}`
      }
    }
    );
    return response
  }
  catch(error){
    console.error("error in fetch workflows",error)
    throw error;
  }

}




