import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { thunk } from 'redux-thunk';   // Correct import for redux-thunk
import authReducer from './reducers/authReducer';  // Correct import for authReducer
import errorSnackbarReducer from './reducers/errorSnackbarReducer';
import ticketReducer from './reducers/ticketReducer';
import summaryReducer from './reducers/summaryReducer';
import searchReducer from './reducers/searchReducer'
import sandboxReducer from './reducers/sandboxReducer';  // Correct import for ticketReducer
import { articleReducer} from './reducers/articleReducer'

// Combine reducers
const rootReducer = combineReducers({
  auth: authReducer,
  ticket: ticketReducer,
  error: errorSnackbarReducer,
  sandbox : sandboxReducer,
  summary : summaryReducer,
  search : searchReducer,
  article : articleReducer

});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Apply middleware
const enhancer = composeEnhancers(
  applyMiddleware(thunk)
);

// Create store
const store = createStore(rootReducer, enhancer);

export default store;
