import { styled } from '@mui/material/styles';
export const ItemHeader = styled('div')({
    color:"#152938",
    fontWeight:"600"
})
export const NoItems = styled('div')({
    color:"#727F88",
    fontWeight:"600",
    fontSize: "0.8rem",
    lineHeight:"1rem"
})
export const TitleContainter = styled('div')({
    display:"flex",
    flexDirection:"column"
})
export  const CustomButton = styled('Button')({
    fontWeight: 700,
    borderRadius: '0.2rem',
    color: '#152938',
    backgroundColor: 'white',
    border: '1px solid #152938',
    fontSize: '80%',
    padding: '0.3rem 0.5rem',
    fontFamily : "Inter, sans-serif",
    '&:hover': {
      color: 'white',
      backgroundColor: '#152938',
    },
  });

export const TicketContainer = styled('div')({
    display:"flex",
    width:"100%",
    justifyContent:"space-between"
})
export const TicketDetails = styled('div')({
    textOverflow:"ellipsis",
    overflow:"hidden",
    width:"70%",
    whiteSpace:"nowrap",
    marginBottom:"0.5rem"
})

export const TicketLikeDislikeContainer = styled('div')({
    width:"10%",
    display:"flex",
    justifyContent:"space-between"
})

export const ArticleTitleContainer = styled('div')({
    display:"flex",
    width:"100%",
    justifyContent:"flex-start"
})
export const ArticleTitle = styled('p')({
    color:"#152938",
    fontSize:"1rem",
    fontWeight:700,
    maxWidth:"70%",
    whiteSpace:"nowrap",
    textOverflow:"ellipsis",
    overflow:"hidden"
})
export const ArticleTitleTag = styled("div")({
    backgroundColor:"#152938",
    fontSize:"0.7rem",
    fontWeight:600,
    color:"white",
    height:"50%",
    width:"10%",
    padding:"0.2rem",
    borderRadius:"0.5rem",
    textAlign:"center",
    marginLeft:"0.5rem"
})

export const ArticleContainer = styled('div')({
    display:"flex",
    width:"100%",
    flexDirection:"column"
})

export const ArticleActionsContainer = styled('div')({
    display:"flex",
    width:"60%",
    justifyContent:"flex-start",
    marginBottom:"1rem",
    columnGap:"3%"
})

export const GPTAnswers = styled('div')({
    margin:"1rem",
    fontWeight: "400",
    padding: "0.5rem",
    cursor: "pointer"
})