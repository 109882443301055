import { TicketComponent } from "../TicketComponent/TicketComponent"

import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import { CaseSummary } from "./CaseSummary";
import { PastTickets } from "./PastTickets";
import { CustomerSummary } from "./CustomerSummary";
import CircularIndeterminate from "../circularspinner/circularspinner";
import { useSelector } from "react-redux";

const CustomTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    display: 'none',
  }
});

const CustomTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  color: '#152938',
  fontWeight:"600",
  marginRight:"1rem",
  backgroundColor: 'white',
  height:"inherit",
  fontSize:"100%",
  borderRadius:"5px",
  '&.Mui-selected': {
    color: 'white',
    backgroundColor: '#152938',
  },
  '&:hover': {
    color: 'white',
    backgroundColor: '#152938',
  }
  
}));

export const SummaryContent = ({subDomain,ticketingSystem,prismToken, ticketId,orgId,agentDetails,userEmail }) => {
  const options = ["Customer Summary", "Conversation Summary", "Past Tickets"];
  const [value, setValue] = React.useState(0);
  const [isloading,setLoader] = React.useState(false);
  const ticketDetails = useSelector((state)=>state.ticket.ticketDetails);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const renderContent = () => {
    switch (value) {
      case 0:
        return <CustomerSummary subDomain = {subDomain} ticketingSystem={ticketingSystem} ticketDetails={ticketDetails} orgId ={orgId} prismToken={prismToken} userEmail = {userEmail}/>;
      case 1:
        return <CaseSummary subDomain = {subDomain} ticketingSystem={ticketingSystem} ticketDetails={ticketDetails} orgId ={orgId} prismToken={prismToken} userEmail = {userEmail}/>;
      case 2:
        return <PastTickets subDomain = {subDomain} ticketingSystem={ticketingSystem} ticketDetails={ticketDetails} orgId ={orgId} prismToken={prismToken} userEmail = {userEmail}/>;
      default:
        return null;
    }
  };
  return (
    <>
      {isloading ? <CircularIndeterminate/> :
      <div style={restChildrenStyles}>
        <TicketComponent subDomain={subDomain} ticketingSystem={ticketingSystem} prismToken={prismToken} ticketId={ticketId} orgId={orgId} />
        {ticketId ? <div style={{height:"70%",border:"2px solid black",borderRadius:"0.5rem",padding:"1rem",width:"50%"}}>
            <Box sx={{ width: '100%', bgcolor: 'background.paper' }} style={{justifyContent:"space-between",height:"15%"}}>

                <CustomTabs value={value} onChange={handleChange} centered>
                  {options.map((item, index) => (
                    <CustomTab key={index} label={item} />
                  ))}
              </CustomTabs>

              
            </Box>
          <div style={{height:"80%",border:"1px solid black",borderRadius:"0.5rem",padding:"1rem",marginTop:"1rem"}}>
          {renderContent()}
            
        </div>
        </div> : <div></div>}

        
      </div>}
    </>
  )
}

const restChildrenStyles = {
  position: 'relative',
  top: "6rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "80vh",
  justifyContent:"space-between"
}
