import * as Actions from '../actions/authActions'

const initialState = {
    message: "",
    status: false,
    severity: ""
}

const errorSnackbarReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.OPEN_ERRORSNACKBAR:
            {
                return {
                    ...state,
                    message: action.payload.message ? action.payload.message : "",
                    status: action.payload.status,
                    severity: action.payload.severity,
                    // autoHideDuration: action.payload.autoHideDuration
                }
            }
        default: {
            return state
        }
    }
}

export default errorSnackbarReducer