
import {
    CUSTOMER_SUMMARY_LOADING,
    CASE_SUMMARY_LOADING,
    PAST_TICKETS_LOADING,
    SAVE_CUSTOMER_SUMMARY,
    SAVE_CASE_SUMMARY,
    SAVE_PAST_TICKETS
}
from '../actions/authActions'
const initialState = {
    customerSummaryLoading: false,
    caseSummaryLoading: false,
    pastTicketLoading : false,
    customerSummaryContent : {},
    caseSummaryContent : {},
    pastTickets : []
};

const summaryReducer = (state = initialState, action) => {
    switch (action.type) {
        case CUSTOMER_SUMMARY_LOADING:
            return {
                ...state,
                customerSummaryLoading: action.payload
            };
        case CASE_SUMMARY_LOADING:
            return {
                ...state,
                caseSummaryLoading: action.payload,
            };
        case PAST_TICKETS_LOADING:
            return {
                ...state,
                pastTicketLoading: action.payload,
            };
        case SAVE_CUSTOMER_SUMMARY:
            return {
                ...state,
                customerSummaryContent: action.payload
            };
        case SAVE_CASE_SUMMARY:
            return {
                ...state,
                caseSummaryContent: action.payload,
            };
        case SAVE_PAST_TICKETS:
            return {
                ...state,
                pastTickets: action.payload,
            };
        
        default:
            return state;
    }
  };
  
  export default summaryReducer;