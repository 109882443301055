import { Wrapper,Key,Value } from "./styles"
import {Fragment, useEffect} from "react"
import CircularIndeterminate from "../circularspinner/circularspinner"
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {getCaseSummary} from "../../api";

export const CaseSummary = ({subDomain,ticketingSystem,ticketDetails,orgId,prismToken,userEmail})=>{
    const caseSummaryContent = useSelector((state)=> state.summary.caseSummaryContent);
    const caseSummaryLoading = useSelector((state)=> state.summary.caseSummaryLoading);
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(getCaseSummary(subDomain,ticketingSystem,ticketDetails,userEmail,orgId,prismToken))
    },[])
    return (
        <>
        {caseSummaryLoading ? <CircularIndeterminate height={"20vh"}/> : 
        <Wrapper style={{height:"inherit"}}>
        {Object.entries(caseSummaryContent).map(([key, value]) => (
            <Key key={key}>
                {key} : <Value>{value.split('\n').map((line, index) => <Fragment key={index}>{line}<br/></Fragment>)}</Value>
            </Key>
        ))}
    </Wrapper>}
        </>
    )
}