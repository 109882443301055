import React, { useEffect, useState } from 'react';
import { ReadylyHeader } from "../ReadylyHeader/ReadylyHeader";
import { MdArrowBack } from "react-icons/md";
import { CustomButton } from "./styles";
import { BiLike } from "react-icons/bi";
import { BiDislike } from "react-icons/bi";
import { FaExternalLinkAlt } from "react-icons/fa";
import './styles.css';
import {ADD_TO_GPT_SANDBOX} from '../../redux/actions/authActions'
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate ,useLocation  } from 'react-router-dom';
import { insertTextToTicket, updateEvent } from '../../api';

export const ArticleTransistion = () => {
    const [selectionObj, setSelection] = useState({ before: '', selected: '', after: '' });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [articleLikeColor,setArticleLikeColor] = useState("#a5b2bb");
    const [articleDislikeColor,setArticleDisLikeColor] = useState("#a5b2bb")

    const restChildrenStyles = {
        position: 'relative',
        top: "6rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "80vh",
        justifyContent: "center"
    };

    const { title, source, articleContent,renderType,ticketDetails,orgId,prismToken,userEmail,agentDetails,ticketingSystem,subDomain } = location.state || {};
    useEffect(()=>{
        dispatch((updateEvent(`${renderType}view`,"",ticketDetails?.id,articleContent,userEmail,orgId,prismToken)))
    },[])

    const getSelectedText = () => {
        const selection = window.getSelection();
        if (selection.rangeCount > 0 && selection.toString().length > 0) {
            const range = selection.getRangeAt(0);
            const selectedText = selection.toString();
            const startOffset = range.startOffset;
            const endOffset = range.endOffset;

            const fullText = range.startContainer.textContent;
            const beforeText = fullText.slice(0, startOffset);
            const afterText = fullText.slice(endOffset);

            setSelection({
                before: beforeText,
                selected: selectedText,
                after: afterText
            });
        } else {
            setSelection({ before: '', selected: '', after: '' });
        }
    };

    const gptModify = () =>{
        const content = selectionObj?.selected.length > 0 ? selectionObj?.selected : articleContent
        dispatch((updateEvent(`${renderType}GPT`,"",ticketDetails?.id,content,userEmail,orgId,prismToken)))
        dispatch({
            type: ADD_TO_GPT_SANDBOX,
            payload: {
               action: "append", content: content
            }
          });
          navigate(`/app?subDomain=${subDomain}&ticketId=${ticketDetails?.id}&email=${userEmail}&ticketingSystem=${ticketingSystem}`)
    }

    const insertText = () =>{

        const content = selectionObj?.selected.length > 0 ? selectionObj?.selected : articleContent;
        dispatch((updateEvent(`${renderType}Insert`,"",ticketDetails?.id,content,userEmail,orgId,prismToken)))
        dispatch(insertTextToTicket(content,ticketingSystem,userEmail,orgId,ticketDetails,prismToken))
    }
    

    const handleLikeDislike = (like) => {
        setArticleLikeColor(like ? "darkgreen" : "#a5b2bb");
        setArticleDisLikeColor(like ? "#a5b2bb" : "red");
        dispatch((updateEvent(`${renderType}${like?"like":"dislike"}`,"",ticketDetails?.id,articleContent,userEmail,orgId,prismToken)))
    }
    return (
        <>
            <ReadylyHeader />
            <div style={restChildrenStyles}>
                <div style={{ height: "80%", border: "2px solid black", borderRadius: "0.5rem", padding: "1rem", width: "40%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    <div style={{ cursor: "pointer" }} onClick={()=>navigate(-1)}>
                        <MdArrowBack fontSize={30} /> <span style={{ marginLeft: "1rem" }}> Back to AgentAssist </span>
                    </div>
                    <div style={{ height: "70%", border: "1px solid black", borderRadius: "0.5rem", padding: "1rem" }}>
                        <div id="articlecontent" style={{ height: "100%", overflowY: "scroll" }} onMouseUp={getSelectedText}>
                            <h6>{title}</h6>
                            <div><a style={{ textDecoration: "none", color:"rgb(14, 139, 255)" }} href={source} target="_blank" rel="noopener noreferrer">Click to see source <FaExternalLinkAlt style={{ color: "#152938" }} /> </a> </div>
                            <div>{articleContent}</div>
                        </div>
                    </div>
                    <div style={{ height: "15%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <div style={{ width: "40%", height: "50%", display: "flex" }}>
                            <CustomButton style={{ marginLeft: "2rem" }} onClick={insertText}> Insert </CustomButton>
                            <CustomButton style={{ marginLeft: "2rem" }} onClick={gptModify}> GPT Modify </CustomButton>
                        </div>
                        <div style={{ width: "20%", display: "flex", columnGap: "20%" }}>
                            <BiLike fontSize={30} style={{ cursor: "pointer" ,color: articleLikeColor }} onClick={() => handleLikeDislike(true)}/>
                            <BiDislike fontSize={30} style={{ cursor: "pointer" ,color: articleDislikeColor}} onClick={() => handleLikeDislike(false)}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
