export const SAVE_DOMAIN = 'SAVE_DOMAIN';
export const SAVE_EMAIL = 'SAVE_EMAIL';
export const SAVE_ORG_DETAILS = "SAVE_ORG_DETAILS";
export const OPEN_ERRORSNACKBAR = 'OPEN_ERRORSNACKBAR';
export const ORG_DETAILS_LOADING = "ORG_DETAILS_LOADING"
export const SAVE_AGENT_DETAILS = "SAVE_AGENT_DETAILS"
export const AGENT_DETAILS_LOADING = "AGENT_DETAILS_LOADING"
export const TICKET_DETAILS_LOADING = "TICKET_DETAILS_LOADING"
export const SAVE_TICKET_DETAILS = "SAVE_TICKET_DETAILS"
export const SAVE_TICKET_ID = "SAVE_TICKET_ID"
export const SAVE_ORG_ID = "SAVE_ORG_ID"
export const SAVE_PRISM_TOKEN = "SAVE_PRISM_TOKEN"
export const SAVE_DATA_IMPORT_DETAILS = "SAVE_DATA_IMPORT_DETAILS"
export const DATA_IMPORT_LOADING = "DATA_IMPORT_LOADING"
export const GPT_SANDBOX_LOADING = "GPT_SANDBOX_LOADING"
export const ADD_TO_GPT_SANDBOX = "ADD_TO_GPT_SANDBOX"
export const UPDATE_STACK="UPDATE_STACK"
export const GPT_UNDO = "GPT_UNDO"
export const GPT_REDO = "GPT_REDO"
export const REMOVE_FROM_GPT_SANDBOX = "REMOVE_FROM_GPT_SANDBOX"
export const UPDATE_GPT_SANDBOX_CONTENT = "UPDATE_GPT_SANDBOX_CONTENT"
export const CUSTOMER_SUMMARY_LOADING = "CUSTOMER_SUMMARY_LOADING"
export const CASE_SUMMARY_LOADING = "CASE_SUMMARY_LOADING"
export const PAST_TICKETS_LOADING = "PAST_TICKETS_LOADING"
export const SAVE_CUSTOMER_SUMMARY = "SAVE_CUSTOMER_SUMMARY"
export const SAVE_CASE_SUMMARY = "SAVE_CASE_SUMMARY"
export const SAVE_PAST_TICKETS = "SAVE_PAST_TICKETS"
export const SAVE_AUTHENTICATED_USER="SAVE_AUTHENTICATED_USER"
export const RESEARCH_TAB_LOADING = "RESEARCH_TAB_LOADING"

export const RESEARCH_CURRENT_TAB = "RESEARCH_CURRENT_TAB"


export const saveResearchTab = (tab) => ({
  type: RESEARCH_CURRENT_TAB,
  payload : tab
});


     

export const saveAuthenticatedUser=(isAuthenticatedUser)=>({
  type:SAVE_AUTHENTICATED_USER,
  payload:isAuthenticatedUser
})

export const saveDomain = (domainName) => ({
  type: SAVE_DOMAIN,
  payload: domainName,
});



export const saveEmail = (email) => ({
  type: SAVE_EMAIL,
  payload: email
});
export const saveOrgDetails = (orgDetails) => ({
    type: SAVE_ORG_DETAILS,
    payload: orgDetails
  });

export function toggleSnackbar(status) {
return (dispatch) => {
    dispatch({
    type: OPEN_ERRORSNACKBAR,
    payload: status
    })
}
}
export function saveAgentDetails(status) {
  return (dispatch) => {
      dispatch({
      type: SAVE_AGENT_DETAILS,
      payload: status
      })
  }
  }
export function loadAgentDetails(status) {
  return (dispatch) => {
      dispatch({
      type: AGENT_DETAILS_LOADING,
      payload: status
      })
  }
  }

export function loadOrgDetails(status) {
  return (dispatch) => {
      dispatch({
      type: ORG_DETAILS_LOADING,
      payload: status
      })
  }
}
export function save(status) {
  return (dispatch) => {
      dispatch({
      type: SAVE_TICKET_DETAILS,
      payload: status
      })
  }
}
export function loadTicketDetails(status) {
  return (dispatch) => {
      dispatch({
      type: TICKET_DETAILS_LOADING,
      payload: status
      })
  }
}

export function saveTicketID(status) {
  return (dispatch) => {
      dispatch({
      type: SAVE_TICKET_ID,
      payload: status
      })
  }
}

export function saveOrgID(orgId) {
  return (dispatch) => {
      dispatch({
      type: SAVE_ORG_ID,
      payload: orgId
      })
  }
}

export function savePrismToken(prismToken) {
  return (dispatch) => {
      dispatch({
      type: SAVE_PRISM_TOKEN,
      payload: prismToken
      })
  }
}


















