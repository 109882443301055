import { useEffect } from "react"
import { Key, Wrapper,Ball,PastTicket,Divider,DividerLine,PastTicketDetails,PastTicketSubject } from "./styles"
import CircularIndeterminate from "../circularspinner/circularspinner"
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {getCustomerPastTickets} from '../../api'
export const PastTickets = ({subDomain,ticketingSystem,ticketDetails,orgId,prismToken,userEmail})=>{
    const pastTickets = useSelector((state)=> state.summary.pastTickets);
    const pastTicketLoading = useSelector((state)=> state.summary.pastTicketLoading);
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(getCustomerPastTickets(subDomain,ticketingSystem,ticketDetails,userEmail,orgId,prismToken))
    },[])
    return (
        <>
         {pastTicketLoading ? <CircularIndeterminate height={"20vh"}/> :
             <Wrapper style={{height:"inherit"}}>
                <Wrapper style={{height:"auto",width : "70%",margin:"auto",overflow:"hidden"}}>

                    {pastTickets.map((value,item)=>{
                        return <PastTicket>
                            <Key style={{display:"inline-block",margin:"0"}}>{value?.created_datetime.slice(0,10)}</Key>
                            <Divider>
                                <Ball/>
                                {item === pastTickets.length-1 ? <div></div>:<DividerLine />}
                            </Divider>

                            <PastTicketDetails>
                                <span>Ticket#{value?.id}</span>
                                <span>CSAT : {value?.csat ?? "N/A"}</span>
                                <PastTicketSubject> Subject : {value?.subject}</PastTicketSubject>
                            </PastTicketDetails>

                        </PastTicket>
                    })}

                </Wrapper>
            
             </Wrapper>
                
            }
        </>
           
        
    )
}