import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ItemHeader,NoItems,TitleContainter,CustomButton,TicketContainer,TicketDetails,TicketLikeDislikeContainer,GPTAnswers } from './styles';
import { BiLike } from "react-icons/bi";
import { BiDislike } from "react-icons/bi";
import CircularProgress from '@mui/material/CircularProgress';
import React, { useState } from 'react';
import { updateEvent } from '../../api';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {getGPTAnswer} from '../../redux/actions/ticketActions'
import {getGPTAnswerForSearch} from '../../redux/actions/searchActions'


export const SimilarTickets = ({similarTickets,userEmail,ticketDetails,orgId,prismToken,renderType,subDomain,ticketingSystem,agentDetails,queryText})=>{
    const dispatch = useDispatch();
    const isloading = false;
    const [colors, setColors] = useState({});
    const navigate = useNavigate();
    const ticketAnswerLoading = useSelector((state) => state.ticket.gptAnswerForTicketsLoading);
    const ticketAnswer = useSelector((state) => state.ticket.gptAnswerForTickets);
    const searchAnswerLoading = useSelector((state) => state.search.gptAnswerForTicketsLoading);
    const searchTicketAnswer = useSelector((state) => state.search.gptAnswerForTickets);
    

    const gptAnswerForTicketsLoading = renderType === 'ticket' ? ticketAnswerLoading : searchAnswerLoading;
    const gptAnswerforTickets = renderType === 'ticket' ? ticketAnswer : searchTicketAnswer;
   

    const handleReactionClick = (ticketId, type) => {
      const isLikeAction = type === "like";
      setColors(prevColors => ({
          ...prevColors,
          [ticketId]: {
              like: isLikeAction ? (prevColors[ticketId]?.like === "darkgreen" ? "#a5b2bb" : "darkgreen") : "#a5b2bb",
              dislike: isLikeAction ? "#a5b2bb" : (prevColors[ticketId]?.dislike === "red" ? "#a5b2bb" : "red"),
          }
      }));
      dispatch(updateEvent(`ticket${type}`, ticketId, ticketDetails?.id, ticketDetails?.subject, userEmail, orgId, prismToken));
  };

      const handleGPTClick = ()=>{
          dispatch(renderType === "ticket" ? 
            getGPTAnswer(subDomain,ticketingSystem,ticketDetails,"gptanswerfortickets",agentDetails,userEmail,prismToken,orgId,"TICKETS") : 
            getGPTAnswerForSearch(subDomain,ticketingSystem,queryText,ticketDetails,"gptanswerfortickets",agentDetails,userEmail,prismToken,orgId,"TICKETS"))
        
      }

      const handleGPTAnswer = () =>{
        navigate('/app/article', {
          state: {
              title: "",
              source: "source",
              articleContent: gptAnswerforTickets,
              renderType: 'ticketAnswer',
              ticketDetails: ticketDetails,
              orgId:orgId,
              prismToken:prismToken,
              userEmail:userEmail,
              agentDetails:agentDetails,
              ticketingSystem : ticketingSystem,
              subDomain : subDomain
          }
          
      });
      }
    return (
        <>
       

        {similarTickets.length ? <Accordion style={{border:"none"}}>
        <AccordionSummary 
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
            <TitleContainter>
                <ItemHeader>Similar Tickets</ItemHeader> 
                {isloading ? <NoItems><CircularProgress size = {20}/>
                </NoItems> : <NoItems> {similarTickets.length} Tickets</NoItems> }
            </TitleContainter>
          
        </AccordionSummary>
        <AccordionDetails>
            <div>
                {similarTickets.map((item)=>{
                    return<TicketContainer>
                                <TicketDetails>{item?.ticket_id} - {item?.subject}</TicketDetails>
                                <TicketLikeDislikeContainer> 
                                    <BiLike
                                        style={{ color: colors[item.ticket_id]?.like || "#a5b2bb", cursor: "pointer" }}
                                        onClick={() => handleReactionClick(item.ticket_id,'like')}
                                    />
                                    <BiDislike
                                        style={{ color: colors[item.ticket_id]?.dislike || "#a5b2bb", cursor: "pointer" }}
                                        onClick={() => handleReactionClick(item.ticket_id,'dislike')}
                                    />
                                </TicketLikeDislikeContainer>
                        </TicketContainer>

                })}
            </div>

            {gptAnswerForTicketsLoading ? <CircularProgress size={20} /> : gptAnswerforTickets.length ? <GPTAnswers onClick={()=>(handleGPTAnswer())}>GPT Answer : {gptAnswerforTickets}</GPTAnswers> : <CustomButton onClick={()=>handleGPTClick()}>GPT Answer For Similar Tickets</CustomButton>}

        </AccordionDetails>
      </Accordion> :  <></>}
        </>
    )
}