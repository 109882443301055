// Constants:
const ROUTES = {
    INDEX :  '/',
    NOT_FOUND : '/not-found',
    ONBOARDING : '/home/onboarding',
    DATAIMPORT : '/dataimport',
    GPT : '/app',
    SUMMARY : '/app/summary',
    ARTICLE : "/app/article", 
    RESEARCH : "/app/research",
    AGENT: {
      LOGIN: '/login',
      FORGOT_PASSWORD: '/forgot-password',
    },
    ADMIN: {
      SETTINGS: '/app/settings'
    }
  };
  
  // Exports:
  export default ROUTES;